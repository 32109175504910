import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, ListGroup, Modal, ProgressBar } from 'react-bootstrap';
import WindparkSelector from '../../windpark-selector/WindparkSelector';
import { deselectDuplicateItem } from '../../../../../redux/slices/internalPlanningSlice';
import { duplicateInternalPlanning } from '../../../../../redux/thunks/internalPlanningThunks';

function DuplicateInternalPlanningModal() {
  const selectedDuplicateInternalItem = useSelector(
    state => state.internalPlanning.selectedDuplicateInternalItem
  );

  const dispatch = useDispatch();

  const turbines = useSelector(state => state.turbines.data);

  const [isDuplicating, setIsDuplicating] = useState(false);

  const [progress, setProgress] = useState({
    total: 0,
    completed: 0,
    successful: 0,
    failed: [],
  });
  const [selectedWindpark, setSelectedWindpark] = useState(null);
  const [selectedTurbines, setSelectedTurbines] = useState({});

  const handleSelectWindpark = windpark => {
    setSelectedWindpark(windpark);
  };

  const handleRemoveTurbine = (park, turbineId) => {
    setSelectedTurbines(prev => {
      const updatedPark = prev[park].filter(
        turbine => turbine.wtGid !== turbineId
      );

      if (updatedPark.length === 0) {
        const { [park]: _, ...rest } = prev;
        return rest;
      }

      return {
        ...prev,
        [park]: updatedPark,
      };
    });
  };

  const handleTurbineSelect = turbine => {
    setSelectedTurbines(prev => {
      const currentTurbines = prev[selectedWindpark.naam] || [];

      if (currentTurbines.some(t => t.wtGid === turbine.wtGid)) {
        const updatedTurbines = currentTurbines.filter(
          t => t.wtGid !== turbine.wtGid
        );
        if (updatedTurbines.length === 0) {
          const { [selectedWindpark.naam]: _, ...rest } = prev;
          return rest;
        } else {
          return {
            ...prev,
            [selectedWindpark.naam]: updatedTurbines,
          };
        }
      } else {
        return {
          ...prev,
          [selectedWindpark.naam]: [...currentTurbines, turbine],
        };
      }
    });
  };

  const handleClose = () => {
    setSelectedTurbines({});
    setSelectedWindpark(null);
    setProgress({ total: 0, completed: 0, successful: 0, failed: [] });
    setIsDuplicating(false);

    dispatch(deselectDuplicateItem());
  };

  const handleDuplicate = async () => {
    setIsDuplicating(true);
    const totalTurbines = Object.values(selectedTurbines).flat().length;
    setProgress({
      total: totalTurbines,
      completed: 0,
      successful: 0,
      failed: [],
    });

    for (const park of Object.keys(selectedTurbines)) {
      for (const turbine of selectedTurbines[park]) {
        const duplicateInternalPlanningObject = {
          internalPlanningId: selectedDuplicateInternalItem.id,
          sysUser: selectedDuplicateInternalItem.sysUser,
          wtgNr: turbine.wtGnr,
        };

        try {
          const actionResult = await dispatch(
            duplicateInternalPlanning({ duplicateInternalPlanningObject })
          );

          if (duplicateInternalPlanning.fulfilled.match(actionResult)) {
            setProgress(prev => ({
              ...prev,
              completed: prev.completed + 1,
              successful: prev.successful + 1,
            }));
          } else {
            throw new Error();
          }
        } catch (error) {
          setProgress(prev => ({
            ...prev,
            completed: prev.completed + 1,
            failed: [...prev.failed, turbine.wtGnr],
          }));
        }
      }
    }
  };

  const renderSelectedTurbines = () => {
    return (
      <ListGroup>
        {Object.entries(selectedTurbines).map(([park, turbines]) => (
          <ListGroup.Item key={park} className="selected-turbines-list-item">
            <strong>{park}</strong>
            <ul className="turbine-list">
              {turbines.map(turbine => (
                <li key={turbine.wtGid} className="turbine-list-item">
                  {turbine.wtgManufacturer} - {turbine.naam}
                  {!isDuplicating && (
                    <span
                      className="remove-turbine-icon"
                      onClick={() => handleRemoveTurbine(park, turbine.wtGid)}
                    >
                      &#10060;
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  return (
    <Modal
      show={Boolean(selectedDuplicateInternalItem)}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header closeButton className="modal-header-duplicate">
        <Modal.Title>Dupliceer Planning</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex" style={{ gap: '20px' }}>
          <div style={{ flex: '1' }}>
            <WindparkSelector
              onSelectWindpark={handleSelectWindpark}
              placeholder="Selecteer Windpark"
              disabled={isDuplicating}
            />
            {selectedWindpark && (
              <div className="turbine-checkbox-container mt-3">
                <h5>Selecteer Turbines</h5>
                {turbines
                  .filter(turbine => turbine.ldn === selectedWindpark.ldn)
                  .map(turbine => (
                    <Form.Check
                      key={turbine.wtGid}
                      type="checkbox"
                      label={turbine.wtgManufacturer}
                      onChange={() => handleTurbineSelect(turbine)}
                      className="mb-2"
                      disabled={isDuplicating}
                    />
                  ))}
              </div>
            )}
          </div>
          <div style={{ flex: '1' }}>
            <h5>Geselecteerde Turbines</h5>
            <ListGroup>{renderSelectedTurbines()}</ListGroup>
          </div>
        </div>
        {isDuplicating && (
          <>
            <ProgressBar
              now={(progress.completed / progress.total) * 100}
              className="mt-3"
            />
            <p>{`${progress.completed} van ${progress.total} verzoeken verwerkt`}</p>
            {progress.failed.length > 0 && (
              <div>
                <p>Gefaalde turbines:</p>
                <ul>
                  {progress.failed.map(turbineNr => (
                    <li key={turbineNr}>{turbineNr}</li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Sluiten
        </Button>
        {!isDuplicating && (
          <Button variant="warning" onClick={handleDuplicate}>
            Dupliceer Planning
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default DuplicateInternalPlanningModal;
