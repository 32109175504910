import { createSlice } from '@reduxjs/toolkit';
import {
  updateCbcPlanning,
  deleteCbcPlanning,
  createCbcPlanning,
  fetchCbcPlanning,
} from '../thunks/cbcPlanningThunks';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  selectedCbcItem: null,
};

const cbcPlanningSlice = createSlice({
  name: 'cbcPlanning',
  initialState,
  reducers: {
    selectItem: (state, action) => {
      state.selectedCbcItem = action.payload;
    },
    deselectItem: state => {
      state.selectedCbcItem = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCbcPlanning.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchCbcPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCbcPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateCbcPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.findIndex(
          item => item.id === action.payload.id
        );
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateCbcPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteCbcPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.map(item => {
          if (item.id === action.payload) {
            return { ...item, deleted: true };
          }
          return item;
        });
        state.error = null;
      })
      .addCase(deleteCbcPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createCbcPlanning.pending, state => {
        state.isLoading = true;
      })
      .addCase(createCbcPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(createCbcPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { selectItem, deselectItem } = cbcPlanningSlice.actions;
export default cbcPlanningSlice.reducer;
