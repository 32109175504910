import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './WtgTypeSelector.scss';

function WtgTypeSelector({ wtgTypes, onSelectWtgType }) {
  const handleChange = event => {
    const selectedWtgType = wtgTypes.find(
      wtgType => wtgType.wtgTypeId === event.target.value
    );
    onSelectWtgType(selectedWtgType);
  };

  return (
    <div className="wtg-type-selector">
      <label>WtgType</label>
      <Form.Select onChange={handleChange} disabled={!wtgTypes.length}>
        <option value="">Selecteer een WTG Type</option>
        {wtgTypes.map((wtgType, index) => (
          <option key={index} value={wtgType.wtgTypeId}>
            {wtgType.wtgTypeId}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}

export default WtgTypeSelector;

WtgTypeSelector.propTypes = {
  wtgTypes: PropTypes.arrayOf(
    PropTypes.shape({
      wtgTypeId: PropTypes.string,
    })
  ).isRequired,
  onSelectWtgType: PropTypes.func.isRequired,
};
