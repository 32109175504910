import React from 'react';
import './TimelineLegend.scss';

const TimelineLegend = () => {
  return (
    <div className="timeline-legend">
      <h5>Legenda</h5>
      <div className="legend-item">
        <div className="legend-color internal-override"></div>
        <div className="legend-label">Overschrijven</div>
      </div>
      <div className="legend-item">
        <div className="legend-color internal-manual"></div>
        <div className="legend-label">Handmatig</div>
      </div>
      <div className="legend-item">
        <div className="legend-color external-item sweep"></div>
        <div className="legend-label">Sweep</div>
      </div>
      <div className="legend-item">
        <div className="legend-color external-item sweep declined"></div>
        <div className="legend-label">Sweep afgekeurd</div>
      </div>
      <div className="legend-item">
        <div className="legend-color external-item sweep active"></div>
        <div className="legend-label">Sweep lopend</div>
      </div>
      <div className="legend-item">
        <div className="legend-color external-item lofar"></div>
        <div className="legend-label">Lofar</div>
      </div>
      <div className="legend-item">
        <div className="legend-color cbc-planning"></div>
        <div className="legend-label">CBC</div>
      </div>
    </div>
  );
};

export default TimelineLegend;
