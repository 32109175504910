import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomInputField.scss';

const CustomInputField = ({ name, type }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <div className="custom-input-field">
      <div
        className={`control-feedback-wrapper ${
          meta.touched && meta.error ? 'is-invalid' : ''
        }`}
      >
        <label>{name}</label>
        <Form.Control
          id={field.name}
          name={field.name}
          value={field.value}
          type={type}
          placeholder={`Voer ${name} in...`}
          isInvalid={meta.touched && meta.error}
          className={type === 'date' ? 'date-width' : 'input-width'}
          onChange={e => {
            helpers.setValue(e.target.value);
          }}
          onBlur={() => {
            helpers.setTouched(true);
          }}
        />
        {meta.touched && meta.error ? (
          <div className="tooltip-error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

CustomInputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CustomInputField;
