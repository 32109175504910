import * as Yup from 'yup';

export const initialValues = {
  wtGnr: '',
  wtGpower: '',
  wtgManufacturer: '',
  wtgType: '',
  startDate: '',
  wtgLatitude: '',
  wtgLongitude: '',
  hubheight: '',
  fullLoadHours: '',
  subsidiaryPrice: '',
  subsidiaryTotalkWh: '',
  wtG_Cut_out_ms: '',
  subsidiaryEnddate: '',
  subsidiaryType: '',
  address: '',
  zipcode: '',
  location: '',
  breezeTurbineID: '',
};

export const validationSchema = Yup.object({
  wtGnr: Yup.string().required('Verplicht'),
  wtGpower: Yup.string().required('Verplicht'),
  wtgManufacturer: Yup.string().required('Verplicht'),
  wtgType: Yup.string().required('Verplicht'),
  startDate: Yup.date().required('Verplicht'),
  wtgLatitude: Yup.number()
    .typeError('Moet numerieke waarde zijn')
    .required('Verplicht'),
  wtgLongitude: Yup.number()
    .typeError('Moet numerieke waarde zijn')
    .required('Verplicht'),
  hubheight: Yup.number()
    .typeError('Moet numerieke waarde zijn')
    .required('Verplicht'),
  fullLoadHours: Yup.number()
    .typeError('Moet numerieke waarde zijn')
    .required('Verplicht'),
  subsidiaryPrice: Yup.number().typeError('Moet numerieke waarde zijn'),
  subsidiaryTotalkWh: Yup.number().typeError('Moet numerieke waarde zijn'),
  wtG_Cut_out_ms: Yup.number().typeError('Moet numerieke waarde zijn'),
  subsidiaryEnddate: Yup.date(),
  subsidiaryType: Yup.string().required('Verplicht'),
  address: Yup.string().required('Verplicht'),
  zipcode: Yup.string().required('Verplicht'),
  location: Yup.string().required('Verplicht'),
  breezeTurbineID: Yup.number()
    .typeError('Moet numerieke waarde zijn')
    .required('Verplicht'),
});
