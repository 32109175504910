import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import WindparkenTable from './components/windparken-table/WindparkenTable';
import AddWindParkModal from './components/modals/add-windpark-modal/AddWindParkModal';
import { useDispatch } from 'react-redux';
import './Windparken.scss';
import { fetchWtgTypes } from '../../../redux/thunks/wtgTypesThunk';
import { fetchStopTypes } from '../../../redux/thunks/stopTypesThunk';
import { fetchWindparken } from '../../../redux/thunks/windparkenThunks';
import { fetchTurbines } from '../../../redux/thunks/turbinesThunk';
import { openParkCreateModal } from '../../../redux/slices/modalSlice';

function Windparken() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchWtgTypes());
    dispatch(fetchStopTypes());
    dispatch(fetchTurbines());
    dispatch(fetchWindparken());
  });

  return (
    <div className="windparken-container">
      <>
        <AddWindParkModal />
        <Row className="mb-3">
          <Col>
            <div className="add-park-icon">
              <Button
                variant="tertiary"
                className="float-button"
                onClick={() => dispatch(openParkCreateModal())}
              >
                <FontAwesomeIcon icon={faPlus} /> Nieuw Windpark
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <WindparkenTable
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              className="windparken-table"
            ></WindparkenTable>
          </Col>
        </Row>
      </>
    </div>
  );
}

export default Windparken;
