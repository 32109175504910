import React from 'react';
import { Button } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';

function LoginButton() {
  const { instance } = useMsal();

  function handleLogin() {
    instance
      .loginPopup()
      .then(response => {
        console.log('Logged in successfully', response);
      })
      .catch(error => {
        console.error('Login failed', error);
      });
  }

  return (
    <Button className="login-button" variant="primary" onClick={handleLogin}>
      Log in
    </Button>
  );
}

export default LoginButton;
