import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import { convertApiDateToDateString } from '../../pages/features/planning/utilities';

const selectExternalPlanningState = state => state.externalPlanning;

export const selectAllExternalPlanning = createSelector(
  [selectExternalPlanningState],
  externalPlanningState => externalPlanningState.data
);

export const selectTransformedExternalPlanning = createSelector(
  [selectAllExternalPlanning],
  externalPlannings =>
    externalPlannings.map(externalPlanning => {
      return {
        id: uuidv4(),
        group: externalPlanning.serieNr,
        start: new Date(externalPlanning.datumStartWerkGepland),
        end: new Date(externalPlanning.datumWerkGereedGepland),
        source: externalPlanning.source.toLowerCase(),
        content:
          externalPlanning.werkzaamheden || 'Geen werkzaamheden opgegeven',
        externalPlanning: externalPlanning,
        deleted: externalPlanning.deleted,
        tooltip: tooltipContent(externalPlanning),
        status: externalPlanning.status || 'Geen status',
      };
    })
);

export const tooltipContent = externalPlanning => {
  return externalPlanning.deleted
    ? `
    Status: Verwijderd <br /> 
    Verwijderd door: ${externalPlanning.deletedBy || 'Onbekend'} <br />
    Verwijderd op: ${
      convertApiDateToDateString(externalPlanning.deletedOn) || 'Onbekend'
    } <br />
    Type: Externe planning`
    : `
    Opgever: ${externalPlanning.medewerker || 'Onbekend'} <br />
    Bijzonderheden: ${
      externalPlanning.bijzonderheden || 'geen bijzonderheden'
    } <br />
    Capaciteit: ${externalPlanning.capacity + '%' || 'geen beperking opgenomen'}
  `;
};
