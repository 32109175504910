import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
  faWind,
  faCalendarAlt,
  faGears,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/pure_energie_logo.svg';
import backgroundImage from '../../assets/background.jpg';

import './Dashboard.scss';

/**
 * Main nav component
 * route: /dashboard
 */
function Dashboard() {
  const [backgroundImageHolder, setBackgroundImage] = useState(null);
  useEffect(() => {
    setBackgroundImage(backgroundImage);
  }, []);

  const navigate = useNavigate();

  const handleWindParkenClick = () => {
    navigate('/windparken');
  };

  const handlePlanningClick = () => {
    navigate('/planning');
  };
  const handleConfigClick = () => {
    navigate('/configuratie');
  };

  return (
    <Container
      fluid
      className="dashboard-container"
      style={{ backgroundImage: `url(${backgroundImageHolder})` }}
    >
      <Row className="justify-content-center">
        <Col xs={12}>
          <div className="dashboard-content">
            <img src={logo} alt="pure-energie-logo" className="logo" />
            <div className="button-container">
              <Button
                variant="primary"
                size="lg"
                onClick={handleWindParkenClick}
              >
                <FontAwesomeIcon icon={faWind} className="button-icon" />
                Windparken
              </Button>
              <Button
                variant="secondary"
                size="lg"
                onClick={handlePlanningClick}
              >
                <FontAwesomeIcon icon={faCalendarAlt} className="button-icon" />
                Planning
              </Button>
              <Button
                className="config-button"
                size="lg"
                onClick={handleConfigClick}
              >
                <FontAwesomeIcon icon={faGears} className="button-icon" />
                Configuratie
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
