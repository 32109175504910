import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveView,
  setShowDeletedPlanning,
} from '../../../../redux/slices/timelineFilterSlice';
import './TimelineToolbar.scss';

function TimelineToolbar() {
  const dispatch = useDispatch();
  const activeView = useSelector(state => state.timelineFilter.activeView);
  const showDeletedPlanning = useSelector(
    state => state.timelineFilter.showDeletedPlanning
  );

  const handleZoomChange = scaleValue => {
    dispatch(setActiveView(scaleValue));
  };

  const toggleDeleted = () => {
    dispatch(setShowDeletedPlanning(!showDeletedPlanning));
  };

  return (
    <div className="timeline-toolbar">
      <Button
        variant="outline-primary"
        onClick={() => handleZoomChange('day')}
        className={activeView === 'day' ? 'active' : ''}
      >
        Dag
      </Button>
      <Button
        variant="outline-primary"
        onClick={() => handleZoomChange('week')}
        className={activeView === 'week' ? 'active' : ''}
      >
        Week
      </Button>
      <Button
        variant="outline-primary"
        onClick={() => handleZoomChange('month')}
        className={activeView === 'month' ? 'active' : ''}
      >
        Maand
      </Button>
      <Button
        variant="outline-primary"
        onClick={() => handleZoomChange('year')}
        className={activeView === 'year' ? 'active' : ''}
      >
        Jaar
      </Button>
      <div className="trash-icon-container" onClick={toggleDeleted}>
        <div className="trash-button">
          <FontAwesomeIcon
            icon={showDeletedPlanning ? faTrashRestore : faTrash}
            className="trash-icon"
          />
        </div>
        <span className="trash-icon-text">
          {showDeletedPlanning
            ? 'Verberg verwijderde items'
            : 'Toon verwijderde items'}
        </span>
      </div>
    </div>
  );
}

export default TimelineToolbar;
