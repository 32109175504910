import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import {
  deleteCbcPlanning,
  updateCbcPlanning,
} from '../../../../../redux/thunks/cbcPlanningThunks';
import { deselectItem } from '../../../../../redux/slices/cbcPlanningSlice';
import UserContext from '../../../../../contexts/UserContext';
import './CbcModal.scss';
import { convertApiDateToDateString } from '../../utilities';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { convertDateToApiDate } from './../../utilities';

function CbcDetailModal() {
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const selectedCbcItem = useSelector(
    state => state.cbcPlanning.selectedCbcItem
  );

  const [editMode, setEditMode] = useState(false);
  const [cbcItemDetails, setCbcItemDetails] = useState({
    sysUser: '',
    startDate_CET: new Date(),
    endDate_CET: new Date(),
    comment: '',
    cbC_MW: 0,
  });

  useEffect(() => {
    if (selectedCbcItem) {
      setCbcItemDetails({
        ...selectedCbcItem,
        startDate_CET: selectedCbcItem.startDate_CET
          ? new Date(selectedCbcItem.startDate_CET)
          : new Date(),
        endDate_CET: selectedCbcItem.endDate_CET
          ? new Date(selectedCbcItem.endDate_CET)
          : new Date(),
        comment: selectedCbcItem.comment || 'Geen opmerking toegevoegd',
      });
    }
  }, [selectedCbcItem]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    const updatedCbcPlanning = {
      sysUser: user.sysUser,
      startDate: convertDateToApiDate(cbcItemDetails.startDate_CET),
      endDate: convertDateToApiDate(cbcItemDetails.endDate_CET),
      comment: cbcItemDetails.comment || '',
      capacity: cbcItemDetails.cbC_MW || 0,
    };

    dispatch(updateCbcPlanning({ id: cbcItemDetails.id, updatedCbcPlanning }));
    setEditMode(false);
    dispatch(deselectItem());
  };

  const handleChange = (e, key) => {
    const value =
      key === 'startDate_CET' || key === 'endDate_CET' ? e : e.target.value;
    setCbcItemDetails(prevDetails => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  const handleDelete = () => {
    dispatch(deleteCbcPlanning({ id: cbcItemDetails.id, user: user.sysUser }));
    dispatch(deselectItem());
  };

  const handleClose = () => {
    setEditMode(false);
    dispatch(deselectItem());
  };

  return (
    <Modal show={Boolean(selectedCbcItem)} onHide={handleClose} size="lg">
      <Modal.Header closeButton className="modal-header-cbc">
        <Modal.Title>CBC Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-cbc">
        <Form>
          <Form.Group as={Row} className="item-group">
            <Form.Label column sm="4">
              EAN:
            </Form.Label>
            <Col sm="8">
              <Form.Text className="detail-view">
                {cbcItemDetails.ean}
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="item-group">
            <Form.Label column sm="4">
              Laatst gewijzigd door:
            </Form.Label>
            <Col sm="8">
              <Form.Text className="detail-view">
                {cbcItemDetails.sysUser}
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="item-group">
            <Form.Label column sm="4">
              Startdatum:
            </Form.Label>
            <Col sm="8">
              {editMode ? (
                <DatePicker
                  selected={cbcItemDetails.startDate_CET}
                  onChange={date => handleChange(date, 'startDate_CET')}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="yyyy-MM-dd HH:mm"
                  className="datepicker"
                />
              ) : (
                <Form.Text>
                  {convertApiDateToDateString(cbcItemDetails.startDate_CET)}
                </Form.Text>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="item-group">
            <Form.Label column sm="4">
              Einddatum:
            </Form.Label>
            <Col sm="8">
              {editMode ? (
                <DatePicker
                  selected={cbcItemDetails.endDate_CET}
                  onChange={date => handleChange(date, 'endDate_CET')}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="yyyy-MM-dd HH:mm"
                  className="datepicker"
                />
              ) : (
                <Form.Text>
                  {convertApiDateToDateString(cbcItemDetails.endDate_CET)}
                </Form.Text>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="item-group">
            <Form.Label column sm="4">
              Capaciteit (in MW):
            </Form.Label>
            <Col sm="8">
              {editMode ? (
                <Form.Control
                  type="text"
                  value={cbcItemDetails.cbC_MW}
                  onChange={e => {
                    const value = e.target.value;
                    if (value === '' || value.match(/^[0-9]+,?[0-9]{0,2}$/)) {
                      setCbcItemDetails(prevState => ({
                        ...prevState,
                        cbC_MW: value,
                      }));
                    }
                  }}
                  onBlur={e => {
                    const value = e.target.value;
                    if (value.endsWith(',')) {
                      setCbcItemDetails(prevState => ({
                        ...prevState,
                        cbC_MW: value.slice(0, -1),
                      }));
                    }
                  }}
                />
              ) : (
                <Form.Text className="detail-view">
                  {cbcItemDetails.cbC_MW || 0}
                </Form.Text>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mt-3 p-2">
            <Form.Label column sm="4">
              Opmerking:
            </Form.Label>

            {editMode ? (
              <Form.Control
                as="textarea"
                rows={3}
                name="comment"
                value={cbcItemDetails.comment}
                onChange={e => handleChange(e, 'comment')}
                placeholder="Voer opmerking in"
              />
            ) : (
              <Form.Text className="detail-view">
                {cbcItemDetails.comment}
              </Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button variant="secondary" onClick={handleClose} className="btn-cbc">
          Sluiten
        </Button>
        <div>
          {editMode ? (
            <Button onClick={handleSave} className="cbc-save-btn">
              Opslaan
            </Button>
          ) : (
            <>
              {!cbcItemDetails.deleted && (
                <div>
                  <Button
                    variant="danger"
                    className="cbc-del-btn"
                    onClick={handleDelete}
                  >
                    Verwijderen
                  </Button>
                  <Button
                    onClick={handleEdit}
                    variant="primary"
                    className="cbc-edit-btn"
                  >
                    Bewerk
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CbcDetailModal;
