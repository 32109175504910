/**
 * Options for the timeline configuration.
 * @type {Object}
 */
const currentDate = new Date();
const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
const endOfYear = new Date(currentDate.getFullYear() + 1, 0, 1);

export const options = {
  template: function (visItem) {
    visItem.className = visItem.source;
    if (visItem.deleted) {
      visItem.className += ' deleted';
    }

    switch (visItem.status?.toLowerCase()) {
      case 'afgekeurd':
        visItem.className += ' declined';
        break;
      case 'lopend':
        visItem.className += ' active';
        break;
      default:
        break;
    }
    return visItem.content;
  },

  stack: true,
  verticalScroll: true,
  zoomable: true,
  zoomMax: 25000000000,
  height: '100%',
  zoomMin: 3600000,
  editable: false,
  format: {
    minorLabels: {
      minute: 'h:mm a',
      hour: 'ha',
      day: 'MMM D',
      week: 'w',
      month: 'MMM',
      year: 'YYYY',
    },
  },
  zoomKey: 'ctrlKey',
  groupOrder: 'content',
  orientation: 'top',
  start: startOfYear,
  end: endOfYear,
};

/**
 * Adds a mouseover event listener to the timeline instance to show tooltips.
 *
 * @param {Object} timelineInstance The timeline instance.
 * @param {Object} tooltipRef The tooltip reference.
 */
export function showTooltip(timelineInstance, tooltipRef) {
  timelineInstance.on('mouseOver', params => {
    if (params.what !== 'item') return;

    const visItem = timelineInstance.itemSet.items[params.item];
    if (!visItem) return;

    tooltipRef.current.innerHTML = getTooltipContent(visItem.data);
    tooltipRef.current.style.display = 'block';

    visItem.dom.box.addEventListener('mouseleave', () =>
      hideTooltip(tooltipRef)
    );
  });

  timelineInstance.dom.root.addEventListener('mousemove', e => {
    tooltipRef.current.style.top = `${e.clientY + 15}px`;
    tooltipRef.current.style.left = `${e.clientX + 15}px`;
  });
}

export function getTooltipContent(visHoveredItem) {
  return visHoveredItem.tooltip;
}

export function hideTooltip(tooltipRef) {
  tooltipRef.current.style.display = 'none';
}

export const setTimelineView = (timelineInstance, selectedView) => {
  const { start, end } = timelineInstance.current.getWindow();

  // Bereken de midden-datum
  const middleDate = new Date((start.getTime() + end.getTime()) / 2);

  let startDate;
  let endDate;

  switch (selectedView) {
    case 'day':
      startDate = new Date(
        middleDate.getFullYear(),
        middleDate.getMonth(),
        middleDate.getDate()
      );
      endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1);
      break;

    case 'week':
      startDate = new Date(
        middleDate.getFullYear(),
        middleDate.getMonth(),
        middleDate.getDate() - middleDate.getDay()
      );
      endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 7);
      break;

    case 'month':
      startDate = new Date(
        middleDate.getFullYear(),
        middleDate.getMonth(),
        middleDate.getDate()
      );
      endDate = new Date(
        middleDate.getFullYear(),
        middleDate.getMonth() + 1,
        middleDate.getDate()
      );
      break;

    case 'year':
      startDate = new Date(middleDate.getFullYear(), 0, 1);
      endDate = new Date(middleDate.getFullYear() + 1, 0, 1);
      break;

    default:
      break;
  }

  timelineInstance.current.setWindow(startDate, endDate);
};
