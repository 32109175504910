import React from 'react';
import PropTypes from 'prop-types';
import './TransparentBackgroundContainer.scss';

const TransparentBackgroundContainer = ({ children }) => {
  return <div className="transparent-background-container">{children}</div>;
};

TransparentBackgroundContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TransparentBackgroundContainer;
