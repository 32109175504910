import { showToast } from '../redux/slices/toastSlice';
import MockService from './MockService';

export const apiUrl = getApiUrl();

function getApiUrl() {
  const hostname = window.location.hostname;

  if (
    hostname.includes('brave-dune') ||
    hostname.includes('beheer.pure-energie.nl')
  ) {
    return `${process.env.REACT_APP_PROD_API_URL}`;
  }
  return `${process.env.REACT_APP_ACC_API_URL}`;
}

class DataService {
  constructor() {
    this.mockService = new MockService();
  }

  static instance = new DataService();

  static getInstance() {
    return DataService.instance;
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  /**
   * Fetch data from the API.
   * @param {string} url - The URL to fetch data from.
   * @param {object} options - The options for the fetch request.
   * @return {Promise<object>} The response data from the fetch request.
   */
  async fetchApi(url, options = {}) {
    try {
      const response = await fetch(`${apiUrl}${url}`, options);

      if (response.ok) {
        let data;
        try {
          data = await response.json();
        } catch (error) {
          data = {};
        }

        if (['PUT', 'POST', 'DELETE'].includes(options.method?.toUpperCase())) {
          let toastType = 'success';
          let message = 'API heeft verzoek succesvol afgehandeld!';

          if (data.message) {
            message = data.message;
            toastType = 'warning';
          }

          this.dispatch(showToast({ message: message, type: toastType }));
        }

        return { ok: true, data };
      }

      let responseBody = {};
      if (response.headers.get('Content-Type')?.includes('application/json')) {
        responseBody = await response.json();
      }

      const errorText = responseBody.errorMessage
        ? responseBody.errorMessage
        : 'Er is iets mis gegaan!';
      const toastText = `${response.status} ${response.statusText}: ${errorText}`;

      this.dispatch(showToast({ message: toastText, type: 'error' }));
      throw new Error(`HTTP error! status: ${response.status}`);
    } catch (error) {
      return { ok: false, error: error.message };
    }
  }

  /**
   * Fetch all parks data
   * @return {Promise<object>} A promise which resolves with a success status and the data received.
   */
  async fetchAllParksApi() {
    const { data: response } = await this.fetchApi('/Park/All');
    return {
      success: !!response,
      data: response,
    };
  }

  /**
   * Fetch properties of a park given its LDN
   * @param {string} ldn - The LDN of the park.
   * @return {Promise<object>} A promise which resolves with a success status and the data received.
   */
  async fetchParkPropertiesApi(ldn) {
    const { data: response } = await this.fetchApi(`/Park/${ldn}/Property`);
    return {
      success: !!response,
      data: response,
    };
  }

  /**
   * Fetch turbines of a park given its LDN
   * @param {string} ldn - The LDN of the park.
   * @return {Promise<object>} A promise which resolves with a success status and the data received.
   */
  async fetchParkTurbinesApi(ldn) {
    const { data: response } = await this.fetchApi(`/Park/${ldn}/Turbines`);
    return {
      success: !!response,
      data: response,
    };
  }

  /**
   * Add a windpark to the database
   * @param {object} data - The data of the windpark to add.
   * @return {Promise<object>} A promise which resolves with the response of the request.
   */
  async addWindpark(data) {
    return this.fetchApi('/Park/Create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
}

export default DataService;
