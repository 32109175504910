import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { initialValues, validationSchema } from './formConfig';
import { generateDummyData } from './helperFunctions';
import CustomInputField from '../../../../../../shared-components/custom-inputfield/CustomInputField';
import WtgTypeSelector from '../../../wtg-type-selector/WtgTypeSelector';
import './AddTurbineForm.scss';

const AddTurbineForm = ({ addTurbine, wtgTypes }) => {
  return (
    <div className="form-container">
      <h3 className="form-header tertiary-border">Turbine formulier</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          addTurbine(values);
          resetForm();
        }}
      >
        {({ resetForm, setFieldValue }) => {
          const handleSelectWtgType = selectedWtgType => {
            setFieldValue('wtgType', selectedWtgType.wtgTypeId);
          };

          return (
            <Form className="add-turbine-form-container">
              <div className="add-turbine-form">
                <Row>
                  <Col>
                    <CustomInputField name="wtGnr" type="text" />
                    <CustomInputField name="wtGpower" type="text" />
                    <CustomInputField name="wtgManufacturer" type="text" />
                    <WtgTypeSelector
                      wtgTypes={wtgTypes}
                      onSelectWtgType={handleSelectWtgType}
                    />
                    <CustomInputField name="wtgLatitude" type="text" />
                    <CustomInputField name="wtgLongitude" type="text" />
                  </Col>

                  <Col>
                    <CustomInputField name="fullLoadHours" type="text" />
                    <CustomInputField name="breezeTurbineID" type="text" />
                    <CustomInputField name="wtG_Cut_out_ms" type="text" />
                    <CustomInputField name="subsidiaryType" type="text" />
                    <CustomInputField name="subsidiaryPrice" type="text" />
                    <CustomInputField name="subsidiaryTotalkWh" type="text" />
                  </Col>

                  <Col>
                    <CustomInputField name="hubheight" type="text" />
                    <CustomInputField name="address" type="text" />
                    <CustomInputField name="zipcode" type="text" />
                    <CustomInputField name="location" type="text" />
                    <CustomInputField name="subsidiaryEnddate" type="date" />
                    <CustomInputField name="startDate" type="date" />
                  </Col>
                </Row>
                <div className="form-buttons">
                  {process.env.NODE_ENV === 'development' ? (
                    <Button
                      onClick={() => {
                        const data = generateDummyData(wtgTypes);
                        Object.keys(data).forEach(key => {
                          setFieldValue(key, data[key]);
                        });
                      }}
                      className="dummy-turbine-button"
                    >
                      Dummy Turbine
                    </Button>
                  ) : (
                    <div className="dummy-turbine-button"></div>
                  )}
                  <div className="right-buttons">
                    <Button
                      variant="secondary"
                      type="reset"
                      onClick={resetForm}
                      className="form-reset-btn"
                    >
                      Reset
                    </Button>

                    <Button
                      variant="tertiary"
                      type="submit"
                      className="form-submit-btn"
                    >
                      Toevoegen
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

AddTurbineForm.propTypes = {
  addTurbine: PropTypes.func.isRequired,
  wtgTypes: PropTypes.array.isRequired,
};

export default AddTurbineForm;
