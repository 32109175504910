import { createAsyncThunk } from '@reduxjs/toolkit';
import DataService from '../../api/DataService';

const dataService = DataService.getInstance();

export const fetchCbcPlanning = createAsyncThunk(
  'cbcPlanning/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/Planning/CBCPlanning');
      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const updateCbcPlanning = createAsyncThunk(
  'cbcPlanning/update',
  async ({ id, updatedCbcPlanning }, { rejectWithValue }) => {
    updatedCbcPlanning.capacity = convertCommaValueToDecimal(
      updatedCbcPlanning.capacity
    );

    try {
      const response = await dataService.fetchApi(
        `/Planning/CBCPlanning/${id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedCbcPlanning),
        }
      );

      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const deleteCbcPlanning = createAsyncThunk(
  'cbcPlanning/delete',
  async ({ id, user }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        `/Planning/CBCPlanning/${id}/${user}`,
        {
          method: 'DELETE',
        }
      );
      return response.ok ? id : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const createCbcPlanning = createAsyncThunk(
  'cbcPlanning/create',
  async ({ newCbcPlanning }, { rejectWithValue }) => {
    newCbcPlanning.cbC_MW = convertCommaValueToDecimal(newCbcPlanning.cbC_MW);

    try {
      const response = await dataService.fetchApi('/Planning/CBCPlanning', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newCbcPlanning),
      });
      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

function convertCommaValueToDecimal(intOrCommaValue) {
  if (typeof intOrCommaValue === 'string') {
    return parseFloat(intOrCommaValue.replace(',', '.'));
  }
  return intOrCommaValue;
}
