import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';
import UserContext from '../../../../contexts/UserContext';
import {
  addWtgType,
  deleteWtgType,
} from '../../../../redux/thunks/wtgTypesThunk';
import { selectWtgTypes } from '../../../../redux/selectors/wtgTypesSelectors';
import './WtgTypesTable.scss';

const WtgTypesTable = () => {
  const [newWtgType, setNewWtgType] = useState('');
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const wtgTypes = useSelector(selectWtgTypes);

  const handleAddWtgType = () => {
    if (newWtgType.trim() === '') {
      console.error('Het WTG Type ID mag niet leeg zijn');
      return;
    }

    const newConfiguratie = {
      wtgTypeId: newWtgType,
      sysModified: new Date().toISOString(),
      sysCreated: new Date().toISOString(),
      sysUser: user.sysUser,
    };

    dispatch(addWtgType(newConfiguratie)); // Dispatch actie om een nieuw WTG Type toe te voegen
    setNewWtgType('');
  };

  const handleDeleteWtgType = wtgTypeId => {
    dispatch(deleteWtgType({ wtgTypeId, sysUser: user.sysUser })); // Dispatch actie om een WTG Type te verwijderen
  };

  return (
    <div className="wtg-types-table">
      <div className="wtg-types-table">
        <Row className="new-wtg-input m-0 p-0">
          <Col>
            <Form.Control
              type="text"
              value={newWtgType}
              onChange={e => setNewWtgType(e.target.value)}
              placeholder="Voeg nieuw WTG Type ID in"
            />
          </Col>
          <Col>
            <Button
              onClick={handleAddWtgType}
              variant="tertiary"
              className="save-btn"
            >
              Opslaan
            </Button>
          </Col>
        </Row>
        <Table className="mt-3 ">
          <thead>
            <tr>
              <th>WtgType ID</th>
            </tr>
          </thead>
          <tbody className="tbody-container">
            {wtgTypes.map((wtgType, index) => (
              <tr key={index} className="transparent-row">
                <td>{wtgType.wtgTypeId}</td>
                <td style={{ textAlign: 'right' }}>
                  <div className="actions">
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteWtgType(wtgType.wtgTypeId)}
                      className="delete-btn"
                    >
                      Verwijder
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default WtgTypesTable;
