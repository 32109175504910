import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import './TurbinesTable.scss';

function TurbinesTable({ turbines }) {
  function getFontSizeClass(text) {
    if (!text) {
      return;
    }
    if (text.length > 30) {
      return 'small-font';
    } else if (text.length > 12) {
      return 'medium-font';
    } else {
      return 'large-font';
    }
  }

  function renderCellData(data) {
    return data !== null && data !== undefined ? data : '-';
  }
  return (
    <div className="table-container">
      <Table striped bordered hover size="sm" className="table-scrollable">
        <thead>
          <tr>
            <th className="narrow-table-el text-center">TID</th>
            <th className="narrow-table-el text-center">WtgNr</th>
            <th className="narrow-table-el text-center">Vermogen</th>
            <th className="mid-table-el text-center">Naam</th>
            <th className="mid-table-el text-center">Type</th>
            <th className="mid-table-el text-center">Start</th>
            <th className="mid-table-el text-center">Eind</th>
            <th className="narrow-table-el text-center">Lat</th>
            <th className="narrow-table-el text-center">Long</th>
            <th className="narrow-table-el text-center">Hub</th>
            <th className="mid-table-el text-center">Adres</th>
            <th className="mid-table-el text-center">Postcode</th>
            <th className="mid-table-el text-center">Locatie</th>
            <th className="mid-table-el text-center">Sjv</th>
            <th className="mid-table-el text-center">FLH</th>
            <th className="narrow-table-el text-center">Prijs</th>
            <th className="narrow-table-el text-center">TotaalKwh</th>
            <th className="narrow-table-el text-center">S-Eind</th>
            <th className="mid-table-el text-center">Sub type</th>
          </tr>
        </thead>
        <tbody>
          {turbines.map((turbine, index) => (
            <tr key={index}>
              <td className="text-center">{turbine.turbineID}</td>
              <td className={getFontSizeClass(turbine.wtGnr)}>
                {renderCellData(turbine.wtGnr)}
              </td>
              <td className="text-center">{turbine.wtGpower}</td>
              <td className={getFontSizeClass(turbine.wtgManufacturer)}>
                {renderCellData(turbine.wtgManufacturer)}
              </td>
              <td className={getFontSizeClass(turbine.wtgType)}>
                {renderCellData(turbine.wtgType)}
              </td>
              <td className={getFontSizeClass(turbine.startDate)}>
                {renderCellData(
                  turbine.startDate &&
                    new Date(turbine.startDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                )}
              </td>
              <td className={getFontSizeClass(turbine.endDate)}>
                {renderCellData(
                  turbine.endDate &&
                    new Date(turbine.endDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                )}
              </td>
              <td className={getFontSizeClass(turbine.wtgLatitude)}>
                {renderCellData(turbine.wtgLatitude)}
              </td>
              <td className={getFontSizeClass(turbine.wtgLongitude)}>
                {renderCellData(turbine.wtgLongitude)}
              </td>
              <td className={getFontSizeClass(turbine.hubheight)}>
                {renderCellData(turbine.hubheight)}
              </td>
              <td className={getFontSizeClass(turbine.projectnaam)}>
                {renderCellData(turbine.address)}
              </td>
              <td className={getFontSizeClass(turbine.zipcode)}>
                {renderCellData(turbine.zipcode)}
              </td>
              <td className={getFontSizeClass(turbine.location)}>
                {renderCellData(turbine.location)}
              </td>
              <td className={getFontSizeClass(turbine.sjv)}>
                {renderCellData(turbine.sjv)}
              </td>
              <td className={getFontSizeClass(turbine.fullLoadHours)}>
                {renderCellData(turbine.fullLoadHours)}
              </td>
              <td className={getFontSizeClass(turbine.subsidiaryPrice)}>
                {renderCellData(turbine.subsidiaryPrice)}
              </td>
              <td className={getFontSizeClass(turbine.subsidiaryTotalkWh)}>
                {renderCellData(turbine.subsidiaryTotalkWh)}
              </td>
              <td className={getFontSizeClass(turbine.subsidiaryEnddate)}>
                {renderCellData(
                  turbine.subsidiaryEnddate &&
                    new Date(turbine.subsidiaryEnddate).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }
                    )
                )}
              </td>
              <td className={getFontSizeClass(turbine.subsidiaryType)}>
                {renderCellData(turbine.subsidiaryType)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

TurbinesTable.propTypes = {
  turbines: PropTypes.array.isRequired,
};

export default TurbinesTable;
