import moment from 'moment';

export function convertApiDateToDate(apiDateStr) {
  let date = new Date();
  if (apiDateStr && moment(apiDateStr).isValid()) {
    date = moment(apiDateStr).toDate();
  }
  return date;
}

export function convertApiDateToDateString(apiDateStr) {
  let dateString = '';
  if (apiDateStr && moment(apiDateStr).isValid()) {
    dateString = moment(apiDateStr).format('DD-MM-YYYY | HH:mm');
  }
  return dateString;
}

export function convertDateToApiDate(date) {
  if (date && moment(date).isValid()) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }
  return '';
}

export function nullCheck(value) {
  return value === null || value === undefined ? (
    <span className="null-value">-</span>
  ) : (
    value
  );
}
