import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeView: 'year',
  showDeletedPlanning: true,
};

export const timelineFilterSlice = createSlice({
  name: 'planning',
  initialState,
  reducers: {
    setActiveView: (state, action) => {
      state.activeView = action.payload;
    },
    setShowDeletedPlanning: (state, action) => {
      state.showDeletedPlanning = action.payload;
    },
  },
});

export const { setActiveView, setShowDeletedPlanning } =
  timelineFilterSlice.actions;

export default timelineFilterSlice.reducer;
