const getEnvironment = () => {
  switch (window.location.hostname) {
    case 'orange-glacier-073c85403.3.azurestaticapps.net':
      return 'development';
    case 'ambitious-grass-0c5b98103.3.azurestaticapps.net':
      return 'acceptance';
    case 'brave-dune-0ec3aab03.3.azurestaticapps.net':
    case 'beheer.pure-energie.nl':
      return 'production';
    default:
      return 'local-dev';
  }
};

const getRedirectUri = env => {
  if (typeof window !== 'undefined') {
    if (window.location.href.includes('https://beheer.pure-energie.nl')) {
      return 'https://beheer.pure-energie.nl/';
    }
  }

  switch (env) {
    case 'acceptance':
      return 'https://ambitious-grass-0c5b98103.3.azurestaticapps.net';
    case 'development':
      return 'https://orange-glacier-073c85403.3.azurestaticapps.net';
    case 'production':
      return 'https://brave-dune-0ec3aab03.3.azurestaticapps.net';
    default:
      return 'http://localhost:3000';
  }
};

const environment = getEnvironment();
const redirectUri = getRedirectUri(environment);

const msalConfig = {
  auth: {
    clientId: 'f79aab43-6220-4286-a99f-833ddb309d05',
    authority:
      'https://login.microsoftonline.com/b995d2a5-03e1-4a6e-974d-6e8660616956',
    redirectUri: redirectUri,
    postLogoutRedirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    pollIntervalMilliseconds: 0,
  },
};

export default msalConfig;

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
