import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import { selectTimelineGroups } from './turbineSelectors';
import { convertApiDateToDateString } from '../../pages/features/planning/utilities';

const selectCbcPlanningState = state => state.cbcPlanning;

export const selectAllCBCPlanning = createSelector(
  [selectCbcPlanningState],
  selectCBCState => selectCBCState.data
);

export const selectTransformedCBCPlanning = createSelector(
  [selectAllCBCPlanning, selectTimelineGroups],
  (cbcPlanningsItems, turbines) =>
    cbcPlanningsItems
      .map(cbcPlanning => {
        const groupIds = turbines
          .filter(turbine => {
            return turbine.ldn === cbcPlanning.ean;
          })
          .map(turbine => {
            return turbine.id;
          });

        return groupIds.map(groupId => {
          return {
            id: uuidv4(),
            group: groupId,
            start: new Date(cbcPlanning.startDate_CET),
            end: new Date(cbcPlanning.endDate_CET),
            content: cbcPlanning.comment || 'Geen commentaar opgegeven',
            source: 'cbc',
            cbcPlanning: cbcPlanning,
            deleted: cbcPlanning.deleted,
            tooltip: tooltipContent(cbcPlanning),
          };
        });
      })
      .flat()
);

export const tooltipContent = cbcPlanning => {
  return cbcPlanning.deleted
    ? `
    Status: Verwijderd <br /> 
    Verwijderd door: ${cbcPlanning.deletedBy || 'Onbekend'} <br />
    Verwijderd op: ${
      convertApiDateToDateString(cbcPlanning.deletedOn) || 'Onbekend'
    }<br />
    Type: CBC planning`
    : `
    Opgever: ${cbcPlanning.sysUser || 'Onbekend'}, <br />
    Opmerking: ${cbcPlanning.comment || 'geen commentaar opgegeven'}, <br />
    Beperking: ${cbcPlanning.cbC_MW + ' MegaWatt' || 'geen beperking opgenomen'}
  `;
};
