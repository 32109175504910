import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectCurrentWindpark } from '../selectors/windparkenSelectors';
import { setWindparkProperties } from '../slices/windparkenSlice';

import DataService from '../../api/DataService';

const dataService = DataService.getInstance();

export const createWindpark = createAsyncThunk(
  'windparken/createWindpark',
  async (windparkData, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/Park/Create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(windparkData),
      });

      if (response.ok) {
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchWindparken = createAsyncThunk(
  'windparken/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/Park/All');

      if (response.ok) {
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchParkProperties = createAsyncThunk(
  'windparken/fetchParkProperties',
  async (ldn, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(`/Park/${ldn}/Property`);
      if (response.ok) {
        const currentState = getState();
        const currentSelectedPark = selectCurrentWindpark(currentState);

        if (currentSelectedPark && currentSelectedPark.ldn === ldn) {
          dispatch(setWindparkProperties(response.data));
        }
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchParkTurbines = createAsyncThunk(
  'windparken/fetchTurbinesForSelectedPark',
  async (ldn, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(`/Park/${ldn}/Turbines`);
      if (response.ok) {
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
