import React, { useState, useContext } from 'react';
import UserContext from '../../../../../contexts/UserContext';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { convertApiDateToDate, convertDateToApiDate } from '../../utilities';
import './CbcModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { closeCbcCreateModal } from '../../../../../redux/slices/modalSlice';
import { createCbcPlanning } from '../../../../../redux/thunks/cbcPlanningThunks';
import WindparkSelector from '../../windpark-selector/WindparkSelector';

const defaultState = {
  startDate: new Date(),
  endDate: new Date(),
  cbC_MW: 0,
  comment: '',
};

const CbcCreateModal = () => {
  const [selectedWindpark, setSelectedWindpark] = useState(null);

  const handleSelectWindpark = windpark => {
    setSelectedWindpark(windpark);
  };

  const dispatch = useDispatch();
  const isModalVisible = useSelector(
    state => state.modal.cbcCreateModalVisible
  );

  const handleClose = () => {
    setState(defaultState);
    dispatch(closeCbcCreateModal());
  };

  const handleSubmit = async () => {
    const newCbcPlanning = {
      ean: selectedWindpark ? selectedWindpark.ldn : '',
      startDate_CET: convertDateToApiDate(state.startDate),
      endDate_CET: convertDateToApiDate(state.endDate),
      cbC_MW: state.cbC_MW,
      comment: state.comment,
      sysUser: user.sysUser,
    };

    dispatch(createCbcPlanning({ newCbcPlanning: newCbcPlanning }));

    handleClose();
  };

  const user = useContext(UserContext);

  const [state, setState] = useState(defaultState);

  return (
    <Modal show={isModalVisible} onHide={handleClose}>
      <Modal.Header closeButton className="modal-header-cbc">
        <Modal.Title>Nieuwe CBC Planning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Starttijd</Form.Label>
            <div>
              <DatePicker
                selected={convertApiDateToDate(state.startDate)}
                onChange={date =>
                  setState(prevState => ({ ...prevState, startDate: date }))
                }
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="yyyy-MM-dd HH:mm"
                className="datepicker"
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Eindtijd</Form.Label>
            <div>
              <DatePicker
                selected={convertApiDateToDate(state.endDate)}
                onChange={date =>
                  setState(prevState => ({ ...prevState, endDate: date }))
                }
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="yyyy-MM-dd HH:mm"
                className="datepicker"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Windpark</Form.Label>
            <WindparkSelector
              placeholder="Selecteer park"
              onSelectWindpark={handleSelectWindpark}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>CBC MW</Form.Label>
            <Form.Control
              type="text"
              value={state.cbC_MW}
              onChange={e => {
                const value = e.target.value;
                if (value === '' || value.match(/^[0-9]+,?[0-9]{0,2}$/)) {
                  setState(prevState => ({
                    ...prevState,
                    cbC_MW: value,
                  }));
                }
              }}
              onBlur={e => {
                const value = e.target.value;
                if (value.endsWith(',')) {
                  setState(prevState => ({
                    ...prevState,
                    cbC_MW: value.slice(0, -1),
                  }));
                }
              }}
            />
          </Form.Group>

          <Form.Group controlId="formOpmerking">
            <Form.Label>Opmerking</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Voer opmerking in..."
              value={state.comment}
              onChange={e =>
                setState(prevState => ({
                  ...prevState,
                  comment: e.target.value,
                }))
              }
              maxLength="500"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Sluiten
        </Button>
        <Button
          className="submit-btn"
          variant="primary"
          onClick={handleSubmit}
          disabled={!state.startDate || !state.endDate || state.cbC_MW <= 0}
        >
          Opslaan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CbcCreateModal;
