import { configureStore } from '@reduxjs/toolkit';
import cbcPlanningSliceReducer from './slices/cbcPlanningSlice';
import toastReducer from './slices/toastSlice';
import modalReducer from './slices/modalSlice';
import windparkenReducer from './slices/windparkenSlice';
import turbinesReducer from './slices/turbinesSlice';
import stopTypesReducer from './slices/stopTypesSlice';
import externalPlanningReducer from './slices/externalPlanningSlice';
import internalPlanningReducer from './slices/internalPlanningSlice';
import timelineFilterReducer from './slices/timelineFilterSlice';
import wtgTypesReducer from './slices/wtgTypesSlice';
import externalPartyReducer from './slices/externalPartySlice';
import DataService from '../api/DataService';

export const store = configureStore({
  reducer: {
    cbcPlanning: cbcPlanningSliceReducer,
    externalPlanning: externalPlanningReducer,
    internalPlanning: internalPlanningReducer,
    toast: toastReducer,
    modal: modalReducer,
    windparken: windparkenReducer,
    turbines: turbinesReducer,
    stopTypes: stopTypesReducer,
    timelineFilter: timelineFilterReducer,
    wtgTypes: wtgTypesReducer,
    externalParty: externalPartyReducer,
  },
});

const dataService = DataService.getInstance();
dataService.setDispatch(store.dispatch);
