import { createSlice } from '@reduxjs/toolkit';
import {
  fetchInternalPlanning,
  updateInternalPlanning,
  deleteInternalPlanning,
  createInternalPlanning,
  duplicateInternalPlanning,
  overrideExternalPlanning,
} from '../thunks/internalPlanningThunks';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  selectedInternalItem: null,
  selectedDuplicateInternalItem: null,
};

const internalPlanningSlice = createSlice({
  name: 'internalPlanning',
  initialState,
  reducers: {
    selectItem: (state, action) => {
      state.selectedInternalItem = action.payload;
    },
    deselectItem: state => {
      state.selectedInternalItem = null;
    },
    selectDuplicateItem: (state, action) => {
      state.selectedDuplicateInternalItem = action.payload;
    },
    deselectDuplicateItem: state => {
      state.selectedDuplicateInternalItem = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInternalPlanning.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchInternalPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchInternalPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateInternalPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.map(item => {
          return item.id === action.payload.id ? action.payload : item;
        });
        state.error = null;
      })
      .addCase(updateInternalPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteInternalPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.filter(item => item.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteInternalPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createInternalPlanning.pending, state => {
        state.isLoading = true;
      })
      .addCase(createInternalPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(createInternalPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(duplicateInternalPlanning.pending, state => {
        state.isLoading = true;
      })
      .addCase(duplicateInternalPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(duplicateInternalPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(overrideExternalPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(overrideExternalPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  selectItem,
  deselectItem,
  selectDuplicateItem,
  deselectDuplicateItem,
} = internalPlanningSlice.actions;

export default internalPlanningSlice.reducer;
