import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TurbineSelector({ turbines, onSelectTurbine }) {
  const [selectedValue, setSelectedValue] = useState('');
  useEffect(() => {
    setSelectedValue('');
  }, [turbines]);

  const handleChange = event => {
    setSelectedValue(event.target.value);
    const selectedTurbine = turbines.find(
      turbine => turbine.wtGnr === event.target.value
    );
    onSelectTurbine(selectedTurbine);
  };

  return (
    <Form.Select
      value={selectedValue}
      className="turbine-select"
      onChange={handleChange}
      disabled={!turbines.length}
    >
      <option value="">Selecteer een turbine</option>
      {turbines.map(turbine => (
        <option key={turbine.wtGnr} value={turbine.wtGnr}>
          {turbine.wtgManufacturer}
        </option>
      ))}
    </Form.Select>
  );
}

TurbineSelector.propTypes = {
  turbines: PropTypes.arrayOf(
    PropTypes.shape({
      wtGnr: PropTypes.string.isRequired,
      wtgManufacturer: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelectTurbine: PropTypes.func.isRequired,
};

export default TurbineSelector;
