import React from 'react';
import './LandingPage.scss';
import logo from '../../assets/pure_energie_logo.svg';
import LoginButton from './components/LoginButton';

function LandingPage() {
  return (
    <div className="background-image">
      <div className="login-container">
        <img className="pure-energie-logo" src={logo} alt="Pure Energie logo" />
        <h3>Inloggen is vereist voor het gebruik van de beheer applicatie </h3>
        <LoginButton />
      </div>
    </div>
  );
}

export default LandingPage;
