import React from 'react';
import './PlanningModals.scss';
import CbcDetailModal from './cbc-modals/CbcDetailModal';
import CbcCreateModal from './cbc-modals/CbcCreateModal';
import ExternalOverrideModal from './external-modals/ExternalOverrideModal';
import InternalOverrideModal from './internal-modals/InternalOverrideModal';
import InternalPlanningCreateModal from './internal-modals/InternalPlanningCreateModal';
import DuplicateInternalPlanningModal from './internal-modals/DuplicateInternalPlanningModal';

const PlanningModals = () => {
  return (
    <>
      <ExternalOverrideModal></ExternalOverrideModal>
      <InternalOverrideModal></InternalOverrideModal>
      <InternalPlanningCreateModal></InternalPlanningCreateModal>
      <CbcDetailModal></CbcDetailModal>
      <CbcCreateModal></CbcCreateModal>
      <DuplicateInternalPlanningModal></DuplicateInternalPlanningModal>
    </>
  );
};

export default PlanningModals;
