import { createAsyncThunk } from '@reduxjs/toolkit';

import DataService from '../../api/DataService';

const dataService = DataService.getInstance();

export const fetchInternalPlanning = createAsyncThunk(
  'internalPlanning/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/Planning/InternalPlanning');
      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const updateInternalPlanning = createAsyncThunk(
  'internalPlanning/update',
  async ({ id, updatedInternalPlanning }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        `/Planning/InternalPlanning/${id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedInternalPlanning),
        }
      );

      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const deleteInternalPlanning = createAsyncThunk(
  'internalPlanning/delete',
  async ({ id, type, user }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        `/Planning/InternalPlanning/${id}/${type}/${user}`,
        {
          method: 'DELETE',
        }
      );
      return response.ok ? id : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const createInternalPlanning = createAsyncThunk(
  'internalPlanning/create',
  async ({ newInternalPlanning }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        '/Planning/InternalPlanning',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newInternalPlanning),
        }
      );
      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const overrideExternalPlanning = createAsyncThunk(
  'externalPlanning/override',
  async ({ overriddenExternalPlanning }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        `/Planning/InternalPlanning`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(overriddenExternalPlanning),
        }
      );
      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const duplicateInternalPlanning = createAsyncThunk(
  'internalPlanning/duplicate',
  async ({ duplicateInternalPlanningObject }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        '/Planning/duplicate/InternalPlanning',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(duplicateInternalPlanningObject),
        }
      );
      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
