import { createSelector } from '@reduxjs/toolkit';

const selectTurbines = state => state.turbines.data;
export const selectCurrentWindpark = state => state.windparken.selectedWindpark;

export const selectTimelineGroups = createSelector(
  [selectTurbines],
  turbines => {
    return turbines.map(turbine => {
      return {
        id: turbine.wtGnr,
        parkid: turbine.wtGnaam,
        content: turbine.wtgManufacturer || '-',
        ldn: turbine.ldn,
      };
    });
  }
);
const selectAllWindparken = state => state.windparken.data;

export const selectIsTurbineInActiveWindpark = createSelector(
  [selectTurbines, selectAllWindparken],
  (turbines, windparken) => {
    return turbines.map(turbine => {
      const windpark = windparken.find(park => park.naam === turbine.wtGnaam);
      return {
        ...turbine,
        isActive: windpark ? windpark.isActive : false,
      };
    });
  }
);
