import { createSelector } from '@reduxjs/toolkit';
import { selectTransformedExternalPlanning } from './externalPlanningSelectors';
import { selectTransformedInternalPlanning } from './internalPlanningSelectors';
import { selectTransformedCBCPlanning } from './cbcPlanningSelectors';
import {
  selectTimelineGroups,
  selectIsTurbineInActiveWindpark,
} from './turbineSelectors';
import { selectCurrentWindpark } from './windparkenSelectors';

const selectExternalPlanningLoading = state => state.externalPlanning.isLoading;
const selectInternalPlanningLoading = state => state.internalPlanning.isLoading;
const selectCBCPlanningLoading = state => state.cbcPlanning.isLoading;

export const selectIsDataLoading = createSelector(
  [
    selectExternalPlanningLoading,
    selectInternalPlanningLoading,
    selectCBCPlanningLoading,
  ],
  (isExternalLoading, isInternalLoading, isCBCLoading) => {
    return isExternalLoading || isInternalLoading || isCBCLoading;
  }
);

export const selectFilteredVisData = createSelector(
  [
    selectTransformedExternalPlanning,
    selectTransformedInternalPlanning,
    selectTransformedCBCPlanning,
    selectTimelineGroups,
    selectCurrentWindpark,
  ],
  (
    externalPlanning,
    internalPlanning,
    cbcPlanning,
    timelineGroups,
    currentWindpark
  ) => {
    if (!currentWindpark) {
      const timelineData = [
        ...externalPlanning,
        ...internalPlanning,
        ...cbcPlanning,
      ];
      return timelineData;
    } else {
      return [...externalPlanning, ...internalPlanning, ...cbcPlanning].filter(
        planningItem => {
          const group = timelineGroups.find(
            group => group.id === planningItem.group
          );
          return group && group.ldn === currentWindpark.ldn;
        }
      );
    }
  }
);

export const selectFilteredVisGroups = createSelector(
  [
    selectTimelineGroups,
    selectCurrentWindpark,
    selectIsTurbineInActiveWindpark,
  ],
  (timelineGroups, currentWindpark, activeTurbines) => {
    const activeTurbineNames = activeTurbines
      .filter(turbine => turbine.isActive)
      .map(turbine => turbine.wtGnaam);

    let filteredGroups;
    if (currentWindpark) {
      filteredGroups = timelineGroups.filter(
        group =>
          group.parkid === currentWindpark.naam &&
          activeTurbineNames.includes(group.parkid)
      );
    } else {
      filteredGroups = timelineGroups.filter(group =>
        activeTurbineNames.includes(group.parkid)
      );
    }
    return filteredGroups;
  }
);
