import React from 'react';
import { Toast } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './NotificationToast.scss';

const NotificationToast = ({ show, onClose, message, type }) => {
  const toastClassMap = {
    warning: 'bg-warning text-dark',
    error: 'bg-danger text-white',
    success: 'bg-success text-white',
  };

  const toastKey = new Date().getTime();

  const toastClass = toastClassMap[type];
  return (
    <div className="toast-container">
      <Toast
        key={toastKey}
        show={show}
        onClose={onClose}
        delay={5000}
        autohide
        className={toastClass}
      >
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
};

NotificationToast.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['warning', 'error', 'success']),
};

export default NotificationToast;
