import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

function StopTypeSelector({ wtgStopTypes, onSelectStopType, disabled, value }) {
  const handleChange = event => {
    const selectedStopType = wtgStopTypes.find(
      stopType => stopType.name === event.target.value
    );
    onSelectStopType(selectedStopType);
  };

  return (
    <Form.Select
      style={{ width: '250px' }}
      onChange={handleChange}
      disabled={disabled}
      value={value || ''}
    >
      <option value="">Geen selectie</option>
      {wtgStopTypes.map((stopType, index) => (
        <option key={index} value={stopType.name}>
          {stopType.name}
        </option>
      ))}
    </Form.Select>
  );
}
StopTypeSelector.propTypes = {
  wtgStopTypes: PropTypes.arrayOf(
    PropTypes.shape({
      wtgStopTypeId: PropTypes.number.isRequired,
    })
  ).isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onSelectStopType: PropTypes.func.isRequired,
};

export default StopTypeSelector;
