import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../../../../contexts/UserContext';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import './ExternalOverrideModal.scss';
import {
  convertApiDateToDateString,
  convertDateToApiDate,
  nullCheck,
} from '../../utilities';
import { deleteExternalPlanning } from '../../../../../redux/thunks/externalPlanningThunks';
import { overrideExternalPlanning } from '../../../../../redux/thunks/internalPlanningThunks';
import { deselectItem as deselectExternalPlanning } from '../../../../../redux/slices/externalPlanningSlice';

const ExternalOverrideModal = () => {
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const externalPlanning = useSelector(
    state => state.externalPlanning.selectedExternalItem
  );

  const turbines = useSelector(state => state.turbines.data);

  const [isEditing, setIsEditing] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [comment, setComment] = useState('');
  const [capacity, setCapacity] = useState(0);
  const [matchedTurbine, setMatchedTurbine] = useState();

  useEffect(() => {
    if (externalPlanning) {
      setStartDate(new Date(externalPlanning.datumStartWerkGepland));
      setEndDate(new Date(externalPlanning.datumWerkGereedGepland));
      setCapacity(externalPlanning.capacity || 0);

      const match = turbines.find(
        turbine => turbine.wtGnr === externalPlanning.serieNr
      );

      setMatchedTurbine(match.wtGnr);
    }
  }, [externalPlanning, turbines]);

  const handleClose = () => {
    dispatch(deselectExternalPlanning());
    setIsEditing(false);
  };

  const handleSubmit = async () => {
    const overrideItem = {
      type: 'Override',
      sysUser: user.sysUser,
      wtgNr: externalPlanning.serieNr,
      planningId: externalPlanning.nummer,
      source: externalPlanning.source,
      capacity: capacity,
      startDate: convertDateToApiDate(startDate),
      endDate: convertDateToApiDate(endDate),
      modifiedDate: convertDateToApiDate(new Date()),
      comment: comment,
      createDate: convertDateToApiDate(new Date()),
    };

    dispatch(
      overrideExternalPlanning({
        overriddenExternalPlanning: overrideItem,
      })
    );
    dispatch(deselectExternalPlanning());
    setIsEditing(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteExternalPlanning({
        id: externalPlanning.nummer,
        source: externalPlanning.source,
        user: user.sysUser,
      })
    );
    handleClose();
  };

  const shouldRenderActionButtons = () => {
    return (
      !externalPlanning.deleted &&
      externalPlanning.status?.toLowerCase() === 'goedgekeurd'
    );
  };

  const isChanged = () =>
    new Date(externalPlanning.startDatum).getTime() !== startDate.getTime() ||
    new Date(externalPlanning.eindDatum).getTime() !== endDate.getTime();

  const headerClass = externalPlanning
    ? `modal-header-external-${externalPlanning.source.toLowerCase()}`
    : 'modal-header-external';

  return (
    <Modal show={!!externalPlanning} onHide={handleClose} size="lg">
      {externalPlanning && (
        <>
          <Modal.Header closeButton className={headerClass}>
            <Modal.Title>Turbine: {matchedTurbine}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <Row>
                <Col>
                  <Form.Group className="item-group">
                    <Form.Label>Aanmelding datum:</Form.Label>
                    <Form.Text>
                      {nullCheck(
                        convertApiDateToDateString(
                          externalPlanning.datumAanmelding
                        )
                      )}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="item-group">
                    <Form.Label>Aanvraag datum:</Form.Label>
                    <Form.Text>
                      {nullCheck(
                        convertApiDateToDateString(
                          externalPlanning.datumAanvraag
                        )
                      )}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="item-group">
                    <Form.Label>Extension:</Form.Label>
                    <Form.Text>
                      {nullCheck(externalPlanning.extensionData)}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="item-group">
                    <Form.Label>Medewerker:</Form.Label>
                    <Form.Text>
                      {nullCheck(externalPlanning.medewerker)}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="item-group">
                    <Form.Label>Melding type:</Form.Label>
                    <Form.Text>
                      {nullCheck(externalPlanning.meldingType)}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="item-group">
                    <Form.Label>Serie nummer:</Form.Label>
                    <Form.Text>{nullCheck(externalPlanning.serieNr)}</Form.Text>
                  </Form.Group>
                  <Form.Group className="item-group">
                    <Form.Label>Turbine status:</Form.Label>
                    <Form.Text>
                      {nullCheck(externalPlanning.turbineStatus)}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="item-group">
                    <Form.Label>Werk gereed status:</Form.Label>
                    <Form.Text>
                      {nullCheck(externalPlanning.werkGereedStatus)}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="item-group">
                    <Form.Label>Werkzaamheden</Form.Label>
                    <Form.Text>
                      {nullCheck(externalPlanning.werkzaamheden)}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="item-group">
                    <Form.Label>Workflow status:</Form.Label>
                    <Form.Text>
                      {nullCheck(externalPlanning.workflowStatus)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="item-group">
                    <Form.Label>Starttijd:</Form.Label>
                    <div className="date-picker-container">
                      {isEditing ? (
                        <DatePicker
                          timeFormat="HH:mm"
                          selected={startDate}
                          onChange={date => {
                            setStartDate(date);
                          }}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd HH:mm"
                          className="editable"
                        />
                      ) : (
                        <Form.Text>
                          {convertApiDateToDateString(startDate)}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="item-group">
                    <Form.Label>Eindtijd:</Form.Label>
                    <div className="date-picker-container">
                      {isEditing ? (
                        <DatePicker
                          timeFormat="HH:mm"
                          selected={endDate}
                          onChange={date => {
                            setEndDate(date);
                          }}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd HH:mm"
                          className="editable"
                        />
                      ) : (
                        <Form.Text>
                          {convertApiDateToDateString(endDate)}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="item-group">
                    <Form.Label>Capaciteit (%):</Form.Label>
                    {isEditing ? (
                      <Form.Control
                        style={{ width: '75px' }}
                        className="editable"
                        type="number"
                        min="0"
                        max="100"
                        value={capacity}
                        readOnly={false}
                        onChange={e => {
                          let value = parseInt(e.target.value);
                          if (value > 100) {
                            value = 100;
                          }
                          setCapacity(parseInt(value));
                        }}
                      />
                    ) : (
                      <Form.Text>{capacity}</Form.Text>
                    )}
                  </Form.Group>

                  {isEditing && (
                    <Form.Group className="mt-3">
                      <Form.Label>Opmerking</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Voer opmerking in..."
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        maxLength="500"
                      />
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Sluiten
            </Button>
            {shouldRenderActionButtons() && (
              <div>
                {isEditing ? (
                  <Button
                    variant="primary"
                    disabled={!isChanged()}
                    onClick={handleSubmit}
                  >
                    Opslaan
                  </Button>
                ) : (
                  <div className="right-side-container">
                    <div className="right-side-buttons">
                      <Button variant="danger" onClick={handleDelete}>
                        Verwijder
                      </Button>{' '}
                    </div>
                    <div className="right-side-buttons">
                      <Button
                        variant="primary"
                        onClick={() => setIsEditing(true)}
                      >
                        Overschrijf
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ExternalOverrideModal;
