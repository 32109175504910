import React, { useContext } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { AuthContext } from '../contexts/AuthContext';
import PropTypes from 'prop-types';

const ConditionalAuthenticationTemplate = ({ children }) => {
  const noAuth = useContext(AuthContext);

  if (noAuth) {
    // In no-auth mode, just render the children directly
    return children;
  } else {
    // In production mode, use MsalAuthenticationTemplate
    return <MsalAuthenticationTemplate>{children}</MsalAuthenticationTemplate>;
  }
};

export default ConditionalAuthenticationTemplate;

ConditionalAuthenticationTemplate.propTypes = {
  children: PropTypes.object.isRequired,
};
