import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    cbcCreateModalVisible: false,
    internalPlanningCreateModalVisible: false,
    parkCreateModalVisible: false,
  },
  reducers: {
    openCbcCreateModal: state => {
      state.cbcCreateModalVisible = true;
    },
    closeCbcCreateModal: state => {
      state.cbcCreateModalVisible = false;
    },
    openInternalPlanningCreateModal: state => {
      state.internalPlanningCreateModalVisible = true;
    },
    closeInternalPlanningCreateModal: state => {
      state.internalPlanningCreateModalVisible = false;
    },
    openParkCreateModal: state => {
      state.parkCreateModalVisible = true;
    },
    closeParkCreateModal: state => {
      state.parkCreateModalVisible = false;
    },
  },
});

export const {
  openCbcCreateModal,
  closeCbcCreateModal,
  openInternalPlanningCreateModal,
  closeInternalPlanningCreateModal,
  openParkCreateModal,
  closeParkCreateModal,
} = modalSlice.actions;

export default modalSlice.reducer;
