import { createAsyncThunk } from '@reduxjs/toolkit';

import DataService from '../../api/DataService';

const dataService = DataService.getInstance();

export const fetchExternalPlanning = createAsyncThunk(
  'externalPlanning/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/Planning/ExternalPlanning');
      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const deleteExternalPlanning = createAsyncThunk(
  'externalPlanning/delete',
  async ({ id, source, user }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        `/Planning/ExternalPlanning/${id}/${source}/${user}`,
        {
          method: 'DELETE',
        }
      );
      return response.ok ? id : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
