import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  openCbcCreateModal,
  openInternalPlanningCreateModal,
} from '../../../../redux/slices/modalSlice';

export const ContextMenu = ({ jsEvent, onClose }) => {
  const dispatch = useDispatch();

  const menuStyle = {
    position: 'absolute',
    top: jsEvent.clientY - 220,
    left: jsEvent.clientX + 10,
    zIndex: 1000,
  };

  const handleOpenCBCCreateModal = () => {
    dispatch(openCbcCreateModal());
    onClose();
  };
  const handleOpenCreateInternalPlanningModal = () => {
    dispatch(openInternalPlanningCreateModal());
    onClose();
  };

  return (
    <ListGroup style={menuStyle} onClick={e => e.stopPropagation()}>
      <ListGroup.Item
        action
        onClick={handleOpenCreateInternalPlanningModal}
        className="context-menu-item"
      >
        Nieuwe handmatige planning
      </ListGroup.Item>
      <ListGroup.Item
        action
        onClick={handleOpenCBCCreateModal}
        className="context-menu-item"
      >
        Nieuwe CBC planning
      </ListGroup.Item>
    </ListGroup>
  );
};

ContextMenu.propTypes = {
  jsEvent: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
