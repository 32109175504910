import { createSlice } from '@reduxjs/toolkit';
import {
  fetchExternalPlanning,
  deleteExternalPlanning,
} from '../thunks/externalPlanningThunks';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  selectedExternalItem: null,
};

const externalPlanningSlice = createSlice({
  name: 'externalPlanning',
  initialState,
  reducers: {
    selectItem: (state, action) => {
      state.selectedExternalItem = action.payload;
    },
    deselectItem: state => {
      state.selectedExternalItem = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchExternalPlanning.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchExternalPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchExternalPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteExternalPlanning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.map(item => {
          if (item.nummer === action.payload) {
            return { ...item, deleted: true };
          }
          return item;
        });
        state.error = null;
      })

      .addCase(deleteExternalPlanning.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { selectItem, deselectItem } = externalPlanningSlice.actions;
export default externalPlanningSlice.reducer;
