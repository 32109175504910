import React, { useState, useEffect } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import TurbinesTable from '../../turbines-table/TurbinesTable';
import PropertiesTable from '../../properties-table/PropertiesTable';
import { useSelector, useDispatch } from 'react-redux';
import { deselectWindpark } from '../../../../../../redux/slices/windparkenSlice';
import './ParkDetailsModal.scss';

function ParkDetailsModal() {
  const [showTurbines, setShowTurbines] = useState(true);
  const [showProperties, setShowProperties] = useState(true);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const park = useSelector(state => state.windparken.selectedWindpark);

  const turbines = park?.turbines || [];
  const properties = park?.properties || [];

  useEffect(() => {
    if (park) {
      setShowModal(true);
    }
  }, [park]);

  const handleClose = () => {
    setShowModal(false);
    dispatch(deselectWindpark());
    setShowTurbines(true);
    setShowProperties(true);
  };

  return (
    park && (
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="custom-modal-dialog"
        className="park-details-modal"
      >
        <>
          <Modal.Header closeButton className="modal-header-override">
            <Modal.Title>{park.naam}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <div className="section-container">
              <div className="header-container">
                <Button
                  className="toggle-button"
                  variant={showTurbines ? 'secondary' : 'primary'}
                  onClick={() => setShowTurbines(!showTurbines)}
                >
                  {showTurbines ? 'Verberg' : 'Toon'}
                </Button>
                <h3>Turbines ({park.projectNaam})</h3>
              </div>
              {showTurbines && turbines && (
                <TurbinesTable turbines={turbines} />
              )}
              {!showTurbines && (
                <Card className="placeholder-card">
                  <Card.Body>{'> Turbines <'}</Card.Body>
                </Card>
              )}
            </div>

            <div className="section-container">
              <div className="header-container">
                <Button
                  className="toggle-button"
                  onClick={() => setShowProperties(!showProperties)}
                  variant={showProperties ? 'secondary' : 'primary'}
                >
                  {showProperties ? 'Verberg' : 'Toon'}
                </Button>
                <h3>Eigenschappen </h3>
              </div>
              {showProperties && properties && (
                <PropertiesTable properties={properties} />
              )}
              {!showProperties && (
                <Card className="placeholder-card">
                  <Card.Body>{'> Eigenschappen <'}</Card.Body>
                </Card>
              )}
            </div>
          </Modal.Body>
        </>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
}

export default ParkDetailsModal;
