import { createSlice } from '@reduxjs/toolkit';
import { fetchWindparken } from '../thunks/windparkenThunks';
import { fetchParkTurbines } from '../thunks/windparkenThunks';
import { createWindpark } from './../thunks/windparkenThunks';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  selectedWindpark: null,
};

const windparkenSlice = createSlice({
  name: 'windparken',
  initialState,
  reducers: {
    selectWindpark: (state, action) => {
      state.selectedWindpark = action.payload;
    },
    deselectWindpark: state => {
      state.selectedWindpark = null;
    },
    setWindparkProperties: (state, action) => {
      if (state.selectedWindpark) {
        state.selectedWindpark.properties = action.payload;
      }
    },
    setWindparkTurbines: (state, action) => {
      if (state.selectedWindpark) {
        state.selectedWindpark.turbines = action.payload;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWindparken.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchWindparken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchWindparken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchParkTurbines.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchParkTurbines.fulfilled, (state, action) => {
        if (state.selectedWindpark) {
          state.selectedWindpark.turbines = action.payload;
          state.isLoading = false;
        }
      })
      .addCase(fetchParkTurbines.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createWindpark.pending, state => {
        state.isLoading = true;
      })
      .addCase(createWindpark.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(createWindpark.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  selectWindpark,
  deselectWindpark,
  setWindparkProperties,
  setWindparkTurbines,
} = windparkenSlice.actions;

export default windparkenSlice.reducer;
