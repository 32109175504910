import { createSlice } from '@reduxjs/toolkit';
import {
  fetchStopTypes,
  addStopType,
  deleteStopType,
} from '../thunks/stopTypesThunk';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const stopTypesSlice = createSlice({
  name: 'stopTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStopTypes.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchStopTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchStopTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addStopType.pending, state => {
        state.isLoading = true;
      })
      .addCase(addStopType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addStopType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteStopType.pending, state => {
        state.isLoading = true;
      })
      .addCase(deleteStopType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.filter(
          stopType => stopType.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteStopType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default stopTypesSlice.reducer;
