import { createAsyncThunk } from '@reduxjs/toolkit';
import DataService from '../../api/DataService';

const dataService = DataService.getInstance();

export const fetchExternalParties = createAsyncThunk(
  'externalParty/fetchExternalParties',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/OptionSet/ExternalParty');
      if (response.ok) {
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
