import { createSelector } from '@reduxjs/toolkit';

export const selectStopTypes = state => state.stopTypes.data;

export const selectStopTypeById = createSelector(
  [selectStopTypes, (_, stopTypeId) => stopTypeId],
  (stopTypes, stopTypeId) => {
    return (
      stopTypes.find(stopType => stopType.wtgStopTypeId === stopTypeId) || null
    );
  }
);

export const selectStopTypeByName = createSelector(
  [selectStopTypes, (_, stopTypeName) => stopTypeName],
  (stopTypes, stopTypeName) => {
    return stopTypes.find(stopType => stopType.name === stopTypeName) || null;
  }
);
