import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Timeline } from 'vis-timeline/standalone';
import {
  options,
  showTooltip,
  hideTooltip,
  setTimelineView,
} from './timelineConfig';
import {
  selectFilteredVisData,
  selectFilteredVisGroups,
} from '../../../../redux/selectors/combinedSelectors';
import TimelineLegend from '../timeline-legend/TimelineLegend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import { ContextMenu } from './ContextMenu';
import 'vis-timeline/styles/vis-timeline-graph2d.css';
import './CustomTimeline.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectItem as selectCbcItem } from '../../../../redux/slices/cbcPlanningSlice';
import { selectItem as selectExternalItem } from '../../../../redux/slices/externalPlanningSlice';
import { selectItem as selectInternalItem } from '../../../../redux/slices/internalPlanningSlice';

const CustomTimeline = () => {
  const timelineRef = useRef(null);
  const timelineInstance = useRef(null);
  const tooltipRef = useRef(null);
  const [jsEvent, setJsEvent] = useState(null);
  const filteredVisData = useSelector(selectFilteredVisData);
  const filteredVisGroups = useSelector(selectFilteredVisGroups);
  const dispatch = useDispatch();
  const activeView = useSelector(state => state.timelineFilter.activeView);
  const showDeletedPlanning = useSelector(
    state => state.timelineFilter.showDeletedPlanning
  );

  const serializeDatesInObject = useCallback(obj => {
    const serializedObj = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] instanceof Date) {
        serializedObj[key] = obj[key].toISOString();
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        serializedObj[key] = serializeDatesInObject(obj[key]);
      } else {
        serializedObj[key] = obj[key];
      }
    });
    return serializedObj;
  }, []);

  const handleDoubleClick = useCallback(
    clickedPlanning => {
      const serializedVisPlanning = serializeDatesInObject(clickedPlanning);
      switch (clickedPlanning.source) {
        case 'cbc':
          dispatch(selectCbcItem(serializedVisPlanning.cbcPlanning));
          break;
        case 'sweep':
        case 'lofar':
          dispatch(selectExternalItem(serializedVisPlanning.externalPlanning));
          break;
        case 'manual':
        case 'override':
          dispatch(selectInternalItem(serializedVisPlanning.internalPlanning));
          break;
        default:
          break;
      }
      hideTooltip(tooltipRef);
    },
    [dispatch, serializeDatesInObject]
  );

  useEffect(() => {
    const updateData = () => {
      const timelineData = showDeletedPlanning
        ? filteredVisData
        : filteredVisData.filter(visItem => !visItem.deleted);

      timelineInstance.current.setData({
        items: timelineData,
        groups: filteredVisGroups,
      });
    };

    if (timelineInstance.current) {
      updateData();
    } else {
      timelineInstance.current = new Timeline(
        timelineRef.current,
        filteredVisData,
        filteredVisGroups,
        options
      );
      timelineInstance.current.on('doubleClick', event => {
        if (event.item) {
          const clickedItemData =
            timelineInstance.current.itemSet.items[event.item].data;
          handleDoubleClick(clickedItemData);
          hideTooltip(tooltipRef);
        }
      });
      showTooltip(timelineInstance.current, tooltipRef);
    }
  }, [
    filteredVisData,
    filteredVisGroups,
    showDeletedPlanning,
    handleDoubleClick,
  ]);

  useEffect(() => {
    setTimelineView(timelineInstance, activeView);
  }, [activeView]);

  const calculateZoomWindow = isZoomIn => {
    const { start, end } = timelineInstance.current.getWindow();
    const middle = new Date((start.getTime() + end.getTime()) / 2);
    const duration = end.getTime() - start.getTime();

    let newDuration = isZoomIn ? duration / 2 : duration * 2;
    const newStart = new Date(middle.getTime() - newDuration / 2);
    const newEnd = new Date(middle.getTime() + newDuration / 2);

    timelineInstance.current.setWindow(newStart, newEnd);
  };

  const handleZoomIn = () => calculateZoomWindow(true);
  const handleZoomOut = () => calculateZoomWindow(false);

  const handleContextMenu = event => {
    event.preventDefault();

    setJsEvent(event);
  };

  const closeContextMenu = () => {
    setJsEvent(null);
  };

  useEffect(() => {
    if (jsEvent) {
      document.addEventListener('click', closeContextMenu);
    }

    return () => {
      document.removeEventListener('click', closeContextMenu);
    };
  }, [jsEvent]);

  return (
    <>
      <div className="timeline-container" onContextMenu={handleContextMenu}>
        <div className="zoom-controls">
          <button className="zoom-button" onClick={handleZoomIn}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </button>
          <button className="zoom-button" onClick={handleZoomOut}>
            <FontAwesomeIcon icon={faSearchMinus} />
          </button>
        </div>
        <div ref={timelineRef} className="timeline"></div>
      </div>
      <TimelineLegend />
      <div ref={tooltipRef} className="tooltip"></div>
      {jsEvent && <ContextMenu jsEvent={jsEvent} onClose={closeContextMenu} />}
    </>
  );
};

export default CustomTimeline;
