import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import './DashboardIcon.scss';

const DashboardIcon = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === '/' || location.pathname === '/dashboard') {
    return null;
  }

  return (
    <Row className="mb-3">
      <Col>
        <div className="dashboard-icon">
          <Button
            variant="outline-secondary"
            className="dashboard-icon-btn"
            onClick={() => navigate('/dashboard')}
          >
            <FontAwesomeIcon icon={faTachometerAlt} />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default DashboardIcon;
