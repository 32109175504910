import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import LandingPage from '../pages/landing-page/LandingPage';
import Dashboard from '../pages/dashboard/Dashboard';
import SignOutButton from '../pages/shared-components/sign-out-button/SignOutButton';
import Planning from '../pages/features/planning/Planning';
import Windparken from '../pages/features/windparken/Windparken';
import Configuratie from '../pages/features/configuratie/Configuratie';
import UserContext from '../contexts/UserContext';
import DataService from '../api/DataService';
import { hideToast } from '../redux/slices/toastSlice';
import NotificationToast from '../pages/shared-components/notification-toast/NotificationToast';
import DataServiceContext from '../contexts/DataServiceContext';
import { AuthContext } from '../contexts/AuthContext';
import ConditionalAuthenticationTemplate from './ConditionalAuthenticationTemplate';
import DashboardIcon from '../pages/shared-components/dashboard-icon/DashboardIcon';
import { useDispatch, useSelector } from 'react-redux';

function App() {
  const [userData, setUserData] = useState({ sysUser: '' });
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const noAuth = process.env.REACT_APP_NO_AUTH === 'true';
  const toast = useSelector(state => state.toast);
  const dispatch = useDispatch();

  useEffect(() => {
    if (noAuth) {
      // mock user data
      setUserData({ sysUser: 'mockuser@pure-energie.nl' });
    } else if (accounts.length > 0) {
      //MsalUserInstance
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: ['User.Read'],
        })
        .then(response => {
          //ApplicationUserInstance (expand wherever needed)
          setUserData({ sysUser: response.account.username });
        });
    }
  }, [instance, accounts, noAuth]);

  useEffect(() => {
    if (userData && location.pathname === '/') {
      navigate('/dashboard');
    }
  });

  if (userData.sysUser === '') {
    return <LandingPage />;
  }

  return (
    <AuthContext.Provider value={noAuth}>
      <DataServiceContext.Provider value={new DataService()}>
        <NotificationToast
          show={toast.show}
          onClose={() => dispatch(hideToast())}
          message={toast.message}
          type={toast.type}
        />
        <UserContext.Provider value={userData}>
          <DashboardIcon />
          <SignOutButton />
          <Routes>
            <Route
              path="/dashboard"
              element={
                <ConditionalAuthenticationTemplate>
                  <Dashboard />
                </ConditionalAuthenticationTemplate>
              }
            />

            <Route
              path="/planning"
              element={
                <ConditionalAuthenticationTemplate>
                  <Planning />
                </ConditionalAuthenticationTemplate>
              }
            />

            <Route
              path="/windparken"
              element={
                <ConditionalAuthenticationTemplate>
                  <Windparken />
                </ConditionalAuthenticationTemplate>
              }
            />
            <Route
              path="/configuratie"
              element={
                <ConditionalAuthenticationTemplate>
                  <Configuratie />
                </ConditionalAuthenticationTemplate>
              }
            />
          </Routes>
        </UserContext.Provider>
      </DataServiceContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
