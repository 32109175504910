/**
 * MockService class to handle mock data manipulations.
 */
export default class MockService {
  /**
   * Adds fields to an item if they do not exist.
   * @param {object} item - The item to add fields to.
   * @param {object} fields - The fields to add to the item.
   * @returns {object} The item with added fields.
   */
  addFieldsToItem(item, fields) {
    for (const key in fields) {
      if (!item.hasOwnProperty(key)) {
        item[key] = fields[key];
      }
    }
    return item;
  }

  /**
   * Adds missing fields to data. This could be a single item or an array of items.
   * @param {object|array} data - The data to add fields to.
   * @param {object} fields - The fields to add.
   * @returns {object|array} The data with added fields.
   */
  addMissingFields(data, fields) {
    if (Array.isArray(data)) {
      return data.map(item => this.addFieldsToItem(item, fields));
    }
    return this.addFieldsToItem(data, fields);
  }

  /**
   * Checks data for specified fields and adds them if they are missing.
   * @param {object|array} data - The data to check and modify.
   * @param {array} fieldsToCheck - The fields to check in the data.
   * @returns {object|array} The data, potentially with added fields.
   */
  checkAndAddFields(data, fieldsToCheck) {
    // Controleer of de data een array is
    if (Array.isArray(data)) {
      // Pas de functie toe op elk item in de array
      return data.map(item =>
        this.addFieldsToItemWithRandomSource(item, fieldsToCheck)
      );
    } else {
      // Pas de functie toe op het enkele data-object
      return this.addFieldsToItemWithRandomSource(data, fieldsToCheck);
    }
  }
}
