import React, { useState } from 'react';
import { Button, Modal, Form, Table, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddTurbineForm from './add-turbine-form/AddTurbineForm';
import './AddWindParkModal.scss';
import { useSelector, useDispatch } from 'react-redux';
import { closeParkCreateModal } from '../../../../../../redux/slices/modalSlice';
import { createWindpark } from './../../../../../../redux/thunks/windparkenThunks';
import { convertApiDateToDate } from './../../../../planning/utilities';

const AddWindparkModal = () => {
  const dispatch = useDispatch();
  const show = useSelector(state => state.modal.parkCreateModalVisible);
  const wtgTypes = useSelector(state => state.wtgTypes.data);

  const [parkData, setParkData] = useState({
    ldn: '',
    parkName: '',
    projectName: '',
    sysUser: '',
    sjv: '',
    endDate: '',
    createTurbines: [],
  });

  const addTurbine = turbine => {
    setParkData(prevParkData => ({
      ...prevParkData,
      createTurbines: [...prevParkData.createTurbines, turbine],
    }));
  };

  const handleChange = e => {
    console.log(parkData);
    const { name, value } = e.target;
    setParkData(prevParkData => ({
      ...prevParkData,
      [name]: value,
    }));
  };

  const handleDateChange = e => {
    console.log(parkData);

    setParkData(prevParkData => ({
      ...prevParkData,
      endDate: e.target.value,
    }));
  };

  const removeTurbine = index => {
    setParkData(prevParkData => ({
      ...prevParkData,
      createTurbines: prevParkData.createTurbines.filter((_, i) => i !== index),
    }));
  };

  const handleSubmitForm = () => {
    dispatch(createWindpark(parkData));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    console.log(parkData);
    dispatch(closeParkCreateModal());
    setParkData({ ldn: '', parkName: '', projectName: '', createTurbines: [] });
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      dialogClassName="custom-modal-dialog"
      className="add-park-modal"
    >
      <Modal.Header closeButton className="modal-header-override">
        <Modal.Title>Voeg nieuw windpark toe</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-override">
        <Form className="form-override" onSubmit={handleSubmitForm}>
          <h3 className="tertiary-border">Park informatie</h3>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>LDN</Form.Label>
                <Form.Control
                  type="number"
                  name="ldn"
                  placeholder="Voer 18 cijferig ldn in..."
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Parknaam</Form.Label>
                <Form.Control
                  type="text"
                  name="parkName"
                  placeholder="Voer parknaam in..."
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Projectnaam</Form.Label>
                <Form.Control
                  type="text"
                  name="projectName"
                  placeholder="Voer projectnaam in..."
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>SJV</Form.Label>
                <Form.Control
                  type="number"
                  name="projectName"
                  placeholder="Voer SJV in..."
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Eind datum</Form.Label>
                <div>
                  <Form.Control
                    selected={convertApiDateToDate(parkData.endDate)}
                    onChange={handleDateChange}
                    type="date"
                  ></Form.Control>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <h3 className="tertiary-border">Turbines</h3>

          {parkData.createTurbines.length > 0 && (
            <Table striped bordered hover responsive="lg" variant="light">
              <thead>
                <tr className="table-row-turbines">
                  <th className="mid-table-el text-center">wtgManufacturer</th>
                  <th className="mid-table-el text-center">wtGnr</th>
                  <th className="mid-table-el text-center">wtgType</th>
                  <th className="mid-table-el text-center">startDate</th>
                  <th className="narrow-table-el">Verwijder</th>
                </tr>
              </thead>
              <tbody>
                {parkData.createTurbines.map((turbine, index) => {
                  return (
                    <tr key={index}>
                      <td className="mid-table-el text-center">
                        {turbine.wtgManufacturer}
                      </td>
                      <td className="mid-table-el text-center">
                        {turbine.wtGnr}
                      </td>
                      <td className="mid-table-el text-center">
                        {turbine.wtgType}
                      </td>
                      <td className="mid-table-el text-center">
                        {turbine.startDate}
                      </td>
                      <td className="narrow-table-el text-center">
                        <FontAwesomeIcon
                          className="remove-turbine-icon"
                          icon={faTimes}
                          onClick={() => removeTurbine(index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Form>
        <AddTurbineForm addTurbine={addTurbine} wtgTypes={wtgTypes} />
      </Modal.Body>
      <Modal.Footer className="modal-footer-override">
        <Button variant="secondary" onClick={handleCloseModal}>
          Sluiten
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmitForm}>
          Opslaan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddWindparkModal;
