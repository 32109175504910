import { createAsyncThunk } from '@reduxjs/toolkit';
import DataService from '../../api/DataService';

const dataService = DataService.getInstance();

export const fetchWtgTypes = createAsyncThunk('wtgTypes/get', async () => {
  const response = await dataService.fetchApi('/OptionSet/WtgType');
  return response.data;
});

export const addWtgType = createAsyncThunk(
  'wtgTypes/add',
  async (wtgTypeData, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/OptionSet/WtgType', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(wtgTypeData),
      });

      if (response.ok) {
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const deleteWtgType = createAsyncThunk(
  'wtgType/delete',
  async ({ wtgTypeId, sysUser }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        `/OptionSet/WtgType/${wtgTypeId}/${sysUser}`,
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        return { wtgTypeId };
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
