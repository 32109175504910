import { createAsyncThunk } from '@reduxjs/toolkit';

import DataService from '../../api/DataService';

const dataService = DataService.getInstance();

export const fetchTurbinesByWindpark = createAsyncThunk(
  'turbines/fetchByWindpark',
  async ({ ldn }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(`/Park/${ldn}/Turbines`);

      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchTurbines = createAsyncThunk(
  'turbines/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/Turbine/Turbines');
      return response.ok ? response.data : rejectWithValue(response.error);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
