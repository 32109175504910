import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../../../../../contexts/UserContext';
import { Modal, Button, Form } from 'react-bootstrap';
import WindparkSelector from '../../windpark-selector/WindparkSelector';
import TurbineSelector from '../../turbine-selector/TurbineSelector';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import StopTypeSelector from '../../stoptypes-selector/StopTypeSelector';
import { convertDateToApiDate, convertApiDateToDate } from '../../utilities';
import './InternalPlanning.scss';
import { useDispatch, useSelector } from 'react-redux';
import { closeInternalPlanningCreateModal } from '../../../../../redux/slices/modalSlice';
import { createInternalPlanning } from '../../../../../redux/thunks/internalPlanningThunks';

/**
 * Default state for the InternalPlanningCreateModal.
 * @constant {Object}
 */
const defaultState = {
  selectedWindpark: null,
  selectedTurbine: null,
  startDate: Date.now(),
  endDate: Date.now(),
  opmerking: '',
  capacity: 0,
  selectedStopType: null,
};

/**
 * InternalPlanningCreateModal component.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.isOpen - Whether the modal is open.
 * @param {Function} props.onClose - Function to close the modal.
 * @param {Object[]} props.turbines - Array of turbine objects.
 * @param {Function} props.onSubmit - Function to handle form submission.
 * @returns {React.Element} The rendered InternalPlanningCreateModal.
 */
const InternalPlanningCreateModal = () => {
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const modalState = useSelector(state => state.modal);
  const turbines = useSelector(state => state.turbines.data);
  const stopTypes = useSelector(state => state.stopTypes.data);
  const [shownTurbines, setShownTurbines] = useState(null);
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (!modalState.internalPlanningCreateModalVisible) {
      setState(defaultState);
    }
  }, [modalState.internalPlanningCreateModalVisible, state.selectedStopType]);

  const handleSelect = (value, field) => {
    setState(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSelectWindpark = windpark => {
    setState(prevState => ({
      ...prevState,
      selectedWindpark: windpark,
    }));
    if (windpark) {
      const filteredTurbines = turbines.filter(
        turbine => turbine.ldn === windpark.ldn
      );
      setShownTurbines(filteredTurbines);
    }
  };

  /**
   * Handles the form submission.
   */
  const handleSubmit = async () => {
    const planning = {
      type: 'Manual',
      sysUser: user.sysUser,
      wtgNr: state.selectedTurbine.wtGnr,
      planningId: null,
      startDate: convertDateToApiDate(state.startDate),
      endDate: convertDateToApiDate(state.endDate),
      comment: state.opmerking,
      modifiedDate: convertDateToApiDate(new Date()),
      createDate: convertDateToApiDate(new Date()),
      capacity: state.capacity,
      wtgStopTypeId: state.selectedStopType.wtgStopTypeId,
    };

    dispatch(createInternalPlanning({ newInternalPlanning: planning }));

    dispatch(closeInternalPlanningCreateModal());
  };

  const handleClose = () => {
    dispatch(closeInternalPlanningCreateModal());
  };

  return (
    <Modal
      show={modalState.internalPlanningCreateModalVisible}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="modal-header-manual">
        <Modal.Title>Nieuwe Handmatige Planning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formWindpark" className="mb-3">
            <Form.Label>Windpark</Form.Label>
            <WindparkSelector
              className="in-modal"
              onSelectWindpark={handleSelectWindpark}
              placeholder="Selecteer windpark"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Turbine</Form.Label>
            <TurbineSelector
              turbines={shownTurbines || []}
              onSelectTurbine={turbine =>
                handleSelect(turbine, 'selectedTurbine')
              }
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Starttijd</Form.Label>
            <div>
              <DatePicker
                selected={
                  state.startDate ? convertApiDateToDate(state.startDate) : null
                }
                onChange={date =>
                  setState(prevState => ({
                    ...prevState,
                    startDate: convertApiDateToDate(date),
                  }))
                }
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="yyyy-MM-dd HH:mm"
                className="datepicker"
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Eindtijd</Form.Label>
            <div>
              <DatePicker
                selected={
                  state.endDate ? convertApiDateToDate(state.endDate) : null
                }
                onChange={date =>
                  setState(prevState => ({
                    ...prevState,
                    endDate: convertApiDateToDate(date),
                  }))
                }
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="yyyy-MM-dd HH:mm"
                className="datepicker"
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Capaciteit (%)</Form.Label>
            <Form.Control
              style={{ width: '75px' }}
              type="number"
              min="0"
              max="100"
              value={state.capacity}
              onChange={e => {
                let value = parseInt(e.target.value);
                if (value > 100) {
                  value = 100;
                }
                setState(prevState => ({
                  ...prevState,
                  capacity: parseInt(value),
                }));
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Stop Type</Form.Label>
            <StopTypeSelector
              wtgStopTypes={stopTypes}
              onSelectStopType={stopType =>
                handleSelect(stopType, 'selectedStopType')
              }
              value={state.selectedStopType?.name || ''}
            />
          </Form.Group>
          <Form.Group controlId="formOpmerking">
            <Form.Label>Opmerking</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Voer opmerking in..."
              value={state.opmerking}
              onChange={e =>
                setState(prevState => ({
                  ...prevState,
                  opmerking: e.target.value,
                }))
              }
              maxLength="500"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Sluiten
        </Button>
        <Button
          className="submit-btn"
          variant="primary"
          onClick={handleSubmit}
          disabled={
            !state.selectedWindpark ||
            !state.selectedTurbine ||
            !state.startDate ||
            !state.endDate ||
            !state.selectedStopType?.wtgStopTypeId
          }
        >
          Opslaan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InternalPlanningCreateModal;
