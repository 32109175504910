import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './SignOutButton.scss';

function SignOutButton() {
  const [showModal, setShowModal] = useState(false);
  const { instance } = useMsal();

  const handleSignOut = async () => {
    instance.logoutRedirect();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faSignOutAlt}
        onClick={() => setShowModal(true)}
        className="sign-out-icon"
      />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Je staat op het punt je af te melden.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Weet je zeker dat je je af wilt melden?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Blijf ingelogd
          </Button>
          <Button variant="primary" onClick={handleSignOut}>
            Log uit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignOutButton;
