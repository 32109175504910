// thunks/stopTypesThunk.js
import { createAsyncThunk } from '@reduxjs/toolkit';

import DataService from '../../api/DataService';

const dataService = DataService.getInstance();

export const fetchStopTypes = createAsyncThunk(
  'stopTypes/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/OptionSet/StopType');

      if (response.ok) {
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const addStopType = createAsyncThunk(
  'stopTypes/add',
  async (stopTypeData, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi('/OptionSet/StopType', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stopTypeData),
      });

      if (response.ok) {
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const deleteStopType = createAsyncThunk(
  'stopTypes/delete',
  async ({ id, sysUser }, { rejectWithValue }) => {
    try {
      const response = await dataService.fetchApi(
        `/OptionSet/StopType/${id}/${sysUser}`,
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        return id;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
