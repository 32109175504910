import React, { useContext, useState } from 'react';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';
import UserContext from '../../../../contexts/UserContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStopType,
  deleteStopType,
} from '../../../../redux/thunks/stopTypesThunk';
import { selectStopTypes } from '../../../../redux/selectors/stopTypeSelectors';

import './WtgStopTypesTable.scss';

const WtgStopTypesTable = () => {
  const [newWtgStopTypeName, setNewWtgStopTypeName] = useState('');
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const wtgStopTypes = useSelector(selectStopTypes);

  const handleAddStopType = () => {
    if (newWtgStopTypeName.trim() === '') {
      console.error('Het WTG Stop Type ID mag niet leeg zijn');
      return;
    }

    const newWtgStopType = {
      name: newWtgStopTypeName,
      sysUser: user.sysUser,
    };

    dispatch(addStopType(newWtgStopType));
    setNewWtgStopTypeName('');
  };
  const handleDeleteStopType = id => {
    dispatch(deleteStopType({ id: id, sysUser: user.sysUser }));
  };

  return (
    <div className="wtg-stop-types-table">
      <Row className="new-wtg-input m-0 p-0">
        <Col>
          <Form.Control
            type="text"
            value={newWtgStopTypeName}
            onChange={e => setNewWtgStopTypeName(e.target.value)}
            placeholder="Voeg nieuw WTG Stop Type ID in"
          />
        </Col>
        <Col>
          <Button
            onClick={handleAddStopType}
            variant="tertiary"
            className="save-btn"
          >
            Opslaan
          </Button>
        </Col>
      </Row>
      <Table className="mt-3 ">
        <thead>
          <tr>
            <th>WtgStopType ID</th>
          </tr>
        </thead>
        <tbody className="tbody-container">
          {wtgStopTypes.map((wtgStopType, index) => (
            <tr key={index} className="transparent-row">
              <td>{wtgStopType.name}</td>
              <td style={{ textAlign: 'right' }}>
                <div className="actions">
                  <Button
                    variant="danger"
                    onClick={() =>
                      handleDeleteStopType(
                        wtgStopType.wtgStopTypeId,
                        user.username
                      )
                    }
                    className="delete-btn"
                  >
                    Verwijder
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default WtgStopTypesTable;
