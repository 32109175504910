import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WindparkSelector from './windpark-selector/WindparkSelector';
import TimelineToolbar from './timeline-toolbar/TimelineToolbar';
import PlanningModals from './modals/PlanningModals';
import CustomTimeline from './custom-timeline/CustomTimeline';
import { fetchExternalPlanning } from '../../../redux/thunks/externalPlanningThunks';
import { fetchInternalPlanning } from '../../../redux/thunks/internalPlanningThunks';
import { fetchCbcPlanning } from '../../../redux/thunks/cbcPlanningThunks';
import { fetchTurbines } from '../../../redux/thunks/turbinesThunk';
import { fetchTurbinesByWindpark } from './../../../redux/thunks/turbinesThunk';
import './Planning.scss';
import { fetchStopTypes } from '../../../redux/thunks/stopTypesThunk';

/**
 * @component
 * @description Planning component.
 * This component fetches and displays the planning data,
 * allows for adding manual entries, overrides and updating overrides,
 * and selecting a specific windpark to view.
 * @returns {ReactElement} Rendered Planning component
 */
const Planning = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchExternalPlanning());
    dispatch(fetchInternalPlanning());
    dispatch(fetchCbcPlanning());
    dispatch(fetchTurbines());
    dispatch(fetchStopTypes());
  }, [dispatch]);

  const handleSelectWindpark = windpark => {
    if (windpark) {
      dispatch(fetchTurbinesByWindpark({ ldn: windpark.ldn }));
    } else {
      dispatch(fetchTurbines());
    }
  };

  return (
    <div className="planning-container">
      <>
        <PlanningModals />
        <div className="toolbar-wrapper">
          <TimelineToolbar />
        </div>
        <WindparkSelector
          placeholder="Alle parken"
          showInactiveParks={false}
          onSelectWindpark={handleSelectWindpark}
        />
        <div className="timeline-wrapper">
          <CustomTimeline />
        </div>
      </>
    </div>
  );
};

export default Planning;
