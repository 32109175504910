import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { fetchWindparken } from '../../../../redux/thunks/windparkenThunks';
import './WindparkSelector.scss';

function WindparkSelector({
  onSelectWindpark,
  placeholder,
  disabled,
  showInactiveParks,
}) {
  const dispatch = useDispatch();
  const windparken = useSelector(state => state.windparken.data);
  const [selectedWindpark, setSelectedWindpark] = useState(null);

  useEffect(() => {
    dispatch(fetchWindparken());
  }, [dispatch]);

  const handleChange = event => {
    const windpark = windparken.find(
      windpark => windpark.naam === event.target.value
    );

    setSelectedWindpark(windpark);
    onSelectWindpark(windpark);
  };

  const gefilterdeWindparken = showInactiveParks
    ? windparken
    : windparken.filter(windpark => {
        return windpark.isActive;
      });

  return (
    <Form.Select
      className={placeholder === 'Alle parken' ? 'windpark-select' : 'in-modal'}
      onChange={handleChange}
      value={selectedWindpark ? selectedWindpark.naam : ''}
      disabled={disabled}
    >
      <option value="">{placeholder}</option>
      {gefilterdeWindparken.map((windpark, index) => (
        <option key={index} value={windpark.naam}>
          {windpark.naam}
        </option>
      ))}
    </Form.Select>
  );
}

WindparkSelector.propTypes = {
  onSelectWindpark: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showInactiveParks: PropTypes.bool,
};

export default WindparkSelector;
