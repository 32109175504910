import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

const selectInternalPlanningState = state => state.internalPlanning;
const stopTypes = state => state.stopTypes.data;

export const selectAllInternalPlanning = createSelector(
  [selectInternalPlanningState],
  internalPlanningState => internalPlanningState.data
);

export const selectTransformedInternalPlanning = createSelector(
  [selectAllInternalPlanning, stopTypes],
  (internalPlanningsItems, stopTypes) =>
    internalPlanningsItems.map(internalPlanning => {
      const visObject = {
        id: uuidv4(),
        group: internalPlanning.wtgNr,
        source: internalPlanning.type.toLowerCase(),
        start: new Date(internalPlanning.startDate),
        end: new Date(internalPlanning.endDate),
        content: internalPlanning.comment || 'Geen commentaar opgegeven',
        internalPlanning: internalPlanning,
        deleted: !!internalPlanning.deletedBy,
        tooltip: tooltipContent(internalPlanning, stopTypes),
      };
      return visObject;
    })
);

export const tooltipContent = (internalPlanning, stopTypes) => {
  const foundStopType = stopTypes.find(stopType => {
    if (internalPlanning.wtgStopTypeName) {
      return stopType.name === internalPlanning.wtgStopTypeName;
    }
    return stopType.wtgStopTypeId === internalPlanning.wtgStopTypeId;
  }) || { name: 'geen stoptype opgenomen' };

  return `
    Opgever: ${internalPlanning.sysUser || 'Onbekend'} <br />
    Stoptype: ${foundStopType.name} <br />
    Capaciteit: ${internalPlanning.capacity + '%' || 'geen beperking opgenomen'}
  `;
};
