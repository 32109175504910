import { createSlice } from '@reduxjs/toolkit';
import {
  fetchWtgTypes,
  addWtgType,
  deleteWtgType,
} from '../thunks/wtgTypesThunk';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const wtgTypesSlice = createSlice({
  name: 'wtgTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchWtgTypes.pending, state => {
        state.loading = true;
      })
      .addCase(fetchWtgTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchWtgTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addWtgType.pending, state => {
        state.loading = true;
      })
      .addCase(addWtgType.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(addWtgType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteWtgType.pending, state => {
        state.loading = true;
      })
      .addCase(deleteWtgType.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          wtgType => wtgType.wtgTypeId !== action.payload.wtgTypeId
        );
      })
      .addCase(deleteWtgType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default wtgTypesSlice.reducer;
