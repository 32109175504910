import React, { useEffect, useState } from 'react';
import { Accordion, Button, useAccordionButton } from 'react-bootstrap';
import WtgTypesTable from './wtg-types/WtgTypesTable';
import './Configuratie.scss';
import PropTypes from 'prop-types';
import TransparentBackgroundContainer from '../../shared-components/feature-container/TransparentBackgroundContainer';
import WtgStopTypesTable from './wtg-stop-types/WtgStopTypesTable';
import { useDispatch } from 'react-redux';
import { fetchWtgTypes } from '../../../redux/thunks/wtgTypesThunk';
import { fetchStopTypes } from '../../../redux/thunks/stopTypesThunk';

function CustomToggle({
  children,
  eventKey,
  activeEventKey,
  setActiveEventKey,
}) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    setActiveEventKey(eventKey)
  );
  const isActive = activeEventKey === eventKey;

  return (
    <Button
      type="button"
      variant={isActive ? 'secondary' : 'primary'}
      className="wtg-types-btn"
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

CustomToggle.propTypes = {
  children: PropTypes.string.isRequired,
  eventKey: PropTypes.string.isRequired,
  activeEventKey: PropTypes.string.isRequired,
  setActiveEventKey: PropTypes.func.isRequired,
};

const Configuratie = () => {
  const dispatch = useDispatch();
  const [activeEventKey, setActiveEventKey] = useState('0');

  useEffect(() => {
    dispatch(fetchWtgTypes());
    dispatch(fetchStopTypes());
  });

  return (
    <TransparentBackgroundContainer>
      <div className="configuratie-container">
        <div className="configuratie-content">
          <Accordion activeKey={activeEventKey}>
            <div className="accordion-buttons-container">
              <CustomToggle
                eventKey="0"
                activeEventKey={activeEventKey}
                setActiveEventKey={setActiveEventKey}
              >
                Wtg types
              </CustomToggle>
              <CustomToggle
                eventKey="1"
                activeEventKey={activeEventKey}
                setActiveEventKey={setActiveEventKey}
              >
                Stop types
              </CustomToggle>
            </div>
            <div className="accordion-content-container">
              <Accordion.Collapse eventKey="0" as="div">
                <WtgTypesTable />
              </Accordion.Collapse>
              <Accordion.Collapse eventKey="1" as="div">
                <WtgStopTypesTable />
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
      </div>
    </TransparentBackgroundContainer>
  );
};

export default Configuratie;
