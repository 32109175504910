import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import './PropertiesTable.scss';

function PropertiesTable({ properties, enumValues }) {
  const getValue = property => {
    const fields = [
      'stringValue',
      'enumValue',
      'intValue',
      'boolValue',
      'floatValue',
      'guidValue',
    ];

    for (let i = 0; i < fields.length; i++) {
      if (property[fields[i]] !== null) {
        if (property.enumValue !== null) {
          const enumValue =
            enumValues &&
            enumValues.find(value => value.id === property.enumValue);
          return enumValue ? enumValue.name : '-';
        }
        if (fields[i] === 'boolValue') {
          return property[fields[i]] ? 'Ja' : 'Nee';
        }
        if (fields[i] === 'floatValue') {
          return property[fields[i]] + '%';
        }
        return property[fields[i]].toString();
      }
    }

    return '-';
  };

  return (
    <div className="table-container">
      <Table striped bordered hover size="sm" className="table-scrollable">
        <thead>
          <tr>
            <th className="properties-table-el-small">Startdatum</th>
            <th className="properties-table-el-small">Einddatum</th>
            <th className="properties-table-el-large">Type</th>
            <th className="properties-table-el-small">Waarde</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((property, index) => (
            <tr key={index}>
              <td>
                {property.startdate
                  ? new Date(property.startdate).toLocaleDateString('nl-NL', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                  : '-'}
              </td>
              <td>
                {property.endDate
                  ? new Date(property.endDate).toLocaleDateString('nl-NL', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                  : '-'}
              </td>
              <td>{property.type}</td>
              <td>{getValue(property)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

PropertiesTable.propTypes = {
  properties: PropTypes.array.isRequired,
  enumValues: PropTypes.array.isRequired,
};

export default PropertiesTable;
