import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertApiDateToDate,
  convertApiDateToDateString,
  nullCheck,
} from '../../utilities';
import { selectDuplicateItem } from '../../../../../redux/slices/internalPlanningSlice';
import { deselectItem as deselectInternalPlanning } from '../../../../../redux/slices/internalPlanningSlice';
import { deleteInternalPlanning } from '../../../../../redux/thunks/internalPlanningThunks';
import UserContext from '../../../../../contexts/UserContext';
import { updateInternalPlanning } from '../../../../../redux/thunks/internalPlanningThunks';
import StopTypeSelector from '../../stoptypes-selector/StopTypeSelector';
import {
  selectStopTypeById,
  selectStopTypeByName,
} from '../../../../../redux/selectors/stopTypeSelectors';

const InternalOverrideModal = () => {
  const user = useContext(UserContext);

  const dispatch = useDispatch();
  const selectedInternalItem = useSelector(
    state => state.internalPlanning.selectedInternalItem
  );
  const stopTypes = useSelector(state => state.stopTypes.data);
  const [matchedExternalPlanning, setMatchedExternalPlanning] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [capacity, setCapacity] = useState(0);
  const [opmerking, setOpmerking] = useState('');
  const [selectedStopType, setSelectedStopType] = useState(null);

  const handleDelete = () => {
    dispatch(
      deleteInternalPlanning({
        id: selectedInternalItem.id,
        type: selectedInternalItem.type,
        user: user.sysUser,
      })
    );
    dispatch(deselectInternalPlanning());
  };
  const selectedStopTypeById = useSelector(state =>
    selectStopTypeById(state, selectedInternalItem?.wtgStopTypeId)
  );
  const selectedStopTypeByName = useSelector(state =>
    selectStopTypeByName(state, selectedInternalItem?.wtgStopTypeName)
  );

  const externalItems = useSelector(state => state.externalPlanning.data);

  useEffect(() => {
    if (selectedInternalItem) {
      setStartDate(selectedInternalItem.startDate);
      setEndDate(selectedInternalItem.endDate);
      setCapacity(selectedInternalItem.capacity);
      setOpmerking(selectedInternalItem.comment);
      setSelectedStopType(
        selectedInternalItem.wtgStopTypeName
          ? selectedStopTypeByName
          : selectedStopTypeById
      );

      if (selectedInternalItem.planningId !== null) {
        const match = externalItems.find(
          externalItem =>
            externalItem.nummer === selectedInternalItem.planningId
        );
        setMatchedExternalPlanning(match);
      }
    } else {
      setMatchedExternalPlanning(null);
    }
  }, [
    selectedInternalItem,
    externalItems,
    stopTypes,
    selectedStopTypeByName,
    selectedStopTypeById,
  ]);

  const handleClose = () => {
    setIsEditing(false);
    dispatch(deselectInternalPlanning());
  };

  const handleSelectStopType = stopType => {
    setSelectedStopType(stopType);
  };

  const handleOpenDuplicateMenu = () => {
    dispatch(selectDuplicateItem(selectedInternalItem));
    handleClose();
  };

  const handleSubmit = async () => {
    const updatedInternalPlanning = {
      sysUser: user.sysUser,
      startDate: startDate,
      endDate: endDate,
      comment: opmerking,
      capacity: capacity,
      wtgStopTypeId: selectedStopType ? selectedStopType.wtgStopTypeId : 0,
    };

    dispatch(
      updateInternalPlanning({
        id: selectedInternalItem.id,
        updatedInternalPlanning,
      })
    );

    setIsEditing(false);
    dispatch(deselectInternalPlanning());
  };

  const headerClass =
    selectedInternalItem?.type.toLowerCase() === 'override'
      ? 'modal-header-override'
      : 'modal-header-manual';

  return (
    <Modal show={!!selectedInternalItem} onHide={handleClose} size="lg">
      {selectedInternalItem && (
        <>
          <Modal.Header closeButton className={headerClass}>
            <Modal.Title>Turbine: {selectedInternalItem.wtgNr}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {matchedExternalPlanning && (
              <div className="external-reference">
                <div
                  className={matchedExternalPlanning.source.toLowerCase()}
                ></div>
                <div className="external-reference-content">
                  <h5>{matchedExternalPlanning.source} referentie:</h5>
                  <div className="external-reference-detail">
                    <span className="detail-label">Werkzaamheden:</span>
                    <span className="detail-value">
                      {matchedExternalPlanning.werkzaamheden}
                    </span>
                  </div>
                  <div className="external-reference-detail">
                    <span className="detail-label">Starttijd:</span>
                    <span className="detail-value">
                      {convertApiDateToDateString(
                        matchedExternalPlanning.datumStartWerkGepland
                      )}
                    </span>
                  </div>
                  <div className="external-reference-detail">
                    <span className="detail-label">Eindtijd:</span>
                    <span className="detail-value">
                      {nullCheck(
                        matchedExternalPlanning.datumWerkGereedGepland
                          ? convertApiDateToDateString(
                              matchedExternalPlanning.datumWerkGereedGepland
                            )
                          : null
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="container">
              <Form.Group className="item-group">
                <Form.Label>Laatst bewerkt:</Form.Label>
                <Form.Text>
                  {selectedInternalItem.modifiedDate
                    ? convertApiDateToDateString(
                        selectedInternalItem.modifiedDate
                      )
                    : nullCheck(selectedInternalItem.modifiedDate)}
                </Form.Text>
              </Form.Group>
              <Form.Group className="item-group">
                <Form.Label>Datum van creatie:</Form.Label>
                <Form.Text>
                  {nullCheck(
                    convertApiDateToDateString(selectedInternalItem.createDate)
                  )}
                </Form.Text>
              </Form.Group>
              <Form.Group className="item-group">
                <Form.Label>Type:</Form.Label>
                <Form.Text>{nullCheck(selectedInternalItem.type)}</Form.Text>
              </Form.Group>
              <Form.Group className="item-group">
                <Form.Label>Invoerder:</Form.Label>
                <Form.Text>{nullCheck(selectedInternalItem.sysUser)}</Form.Text>
              </Form.Group>

              <Form.Group className="item-group">
                <Form.Label>Starttijd:</Form.Label>
                <div className="date-picker-container">
                  {isEditing ? (
                    <DatePicker
                      timeFormat="HH:mm"
                      className="editable"
                      selected={convertApiDateToDate(startDate)}
                      onChange={date => setStartDate(date)}
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm"
                    />
                  ) : (
                    <Form.Text>
                      {convertApiDateToDateString(startDate)}
                    </Form.Text>
                  )}
                </div>
              </Form.Group>

              <Form.Group className="item-group">
                <Form.Label>Eindtijd:</Form.Label>
                <div className="date-picker-container">
                  {isEditing ? (
                    <DatePicker
                      timeFormat="HH:mm"
                      className="editable"
                      selected={convertApiDateToDate(endDate)}
                      onChange={date => setEndDate(date)}
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm"
                    />
                  ) : (
                    <Form.Text>{convertApiDateToDateString(endDate)}</Form.Text>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="item-group">
                <Form.Label>Capaciteit (%):</Form.Label>
                {isEditing ? (
                  <Form.Control
                    style={{ width: '75px' }}
                    className="editable"
                    type="number"
                    min="0"
                    max="100"
                    value={capacity}
                    readOnly={false}
                    onChange={e => {
                      let value = parseInt(e.target.value);
                      if (value > 100) {
                        value = 100;
                      }
                      setCapacity(parseInt(value));
                    }}
                  />
                ) : (
                  <Form.Text>{capacity}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="item-group">
                <Form.Label column sm="4">
                  Stop Type
                </Form.Label>

                <StopTypeSelector
                  wtgStopTypes={stopTypes}
                  onSelectStopType={handleSelectStopType}
                  disabled={!isEditing}
                  value={selectedStopType?.name}
                />
              </Form.Group>
            </div>

            <Form.Group className="mt-3 p-3">
              <Form.Label>Opmerking</Form.Label>
              {isEditing ? (
                <Form.Control
                  className="editable"
                  as="textarea"
                  placeholder="Voer opmerking in..."
                  onChange={e => setOpmerking(e.target.value)}
                  maxLength="500"
                  value={opmerking}
                />
              ) : (
                <div>
                  <Form.Text>{opmerking}</Form.Text>
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Sluiten
            </Button>
            <div className="right-side-container">
              <div className="right-side-buttons">
                {!isEditing &&
                  selectedInternalItem.type.toLowerCase() !== 'override' && (
                    <Button variant="warning" onClick={handleOpenDuplicateMenu}>
                      Dupliceer
                    </Button>
                  )}
                <Button variant="danger" onClick={handleDelete}>
                  Verwijder
                </Button>
              </div>
              {isEditing ? (
                <div className="right-side-buttons">
                  <Button variant="primary" onClick={handleSubmit}>
                    Opslaan
                  </Button>
                </div>
              ) : (
                <div className="right-side-buttons">
                  <Button variant="primary" onClick={() => setIsEditing(true)}>
                    Bewerk
                  </Button>
                </div>
              )}
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default InternalOverrideModal;
