import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ParkDetailsModal from '../modals/park-details-modal/ParkDetailsModal';
import { selectWindpark } from '../../../../../redux/slices/windparkenSlice';
import './WindparkenTable.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchParkTurbines,
  fetchParkProperties,
} from './../../../../../redux/thunks/windparkenThunks';

function WindparkenTable({ setCurrentPage, currentPage }) {
  const dispatch = useDispatch();

  const data = useSelector(state => state.windparken.data);
  const [itemsPerScreen, setItemsPerScreen] = useState(0);
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerScreen,
    currentPage * itemsPerScreen
  );

  useEffect(() => {
    const calculateItemsPerScreen = () => {
      const rowHeight = document.querySelector('.mid-table-el')?.offsetHeight;
      const tableHeight =
        document.querySelector('.tbody-container')?.offsetHeight;
      if (rowHeight && tableHeight) {
        setItemsPerScreen(Math.floor(tableHeight / rowHeight));
      }
    };

    calculateItemsPerScreen();
  }, [data]);

  const onRowClick = park => {
    dispatch(selectWindpark(park));
    dispatch(fetchParkTurbines(park.ldn));
    dispatch(fetchParkProperties(park.ldn));
  };

  return (
    <>
      <div className="windparken-table-container">
        <Table
          striped
          bordered
          hover
          responsive="lg"
          variant="light"
          className="windparken-table"
        >
          <thead>
            <tr>
              <th className="narrow-table-el">ID</th>
              <th className="mid-table-el">Naam</th>
              <th className="large-table-el">LDN</th>
              <th className="narrow-table-el">Turbines</th>
              <th className="mid-table-el">Begindatum</th>
              <th className="mid-table-el">Einddatum</th>
              <th className="large-table-el">Projectnaam</th>
              <th className="narrow-table-el">SJV</th>
              <th className="mid-table-el">Vollasturen</th>
              <th className="narrow-table-el">Vermogen</th>
            </tr>
          </thead>
          <tbody className="tbody-container">
            {paginatedData.map(park => (
              <tr key={park.id} onClick={() => onRowClick(park)}>
                <td className="narrow-table-el">{park.id}</td>
                <td className="mid-table-el">{park.naam}</td>
                <td className="large-table-el">{park.ldn}</td>
                <td className="narrow-table-el">{park.aantalWtg}</td>
                <td className="mid-table-el">
                  {new Date(park.beginDatum).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                </td>
                <td className="mid-table-el">
                  {park.eindDatum
                    ? new Date(park.eindDatum).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })
                    : '-'}
                </td>

                <td className="large-table-el">
                  {park.projectNaam?.length > 30
                    ? park.projectNaam.substring(0, 30) + '...'
                    : park.projectNaam}
                </td>
                <td className="narrow-table-el">{park.sjv}</td>
                <td className="mid-table-el">{park.vollasturen}</td>
                <td className="narrow-table-el">{park.vermogen}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="10">
                <div className="pagination-container">
                  <div className="pagination">
                    {[
                      ...Array(
                        itemsPerScreen > 0
                          ? Math.ceil(data.length / itemsPerScreen)
                          : 0
                      ).keys(),
                    ].map(number => {
                      return (
                        <button
                          key={number}
                          onClick={() => setCurrentPage(number + 1)}
                          className={currentPage === number + 1 ? 'active' : ''}
                        >
                          {number + 1}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <ParkDetailsModal />
    </>
  );
}

WindparkenTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default WindparkenTable;
